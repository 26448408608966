import { Theme, createStyles } from '@material-ui/core/styles';

/**
 *
 * @param {Theme} theme Material Theam.
 * @returns {Theme} Return Material theams.
 */
const Styles = (theme: Theme) =>
	createStyles({
		root: {
			fontSize: 14,
			lineHeight: 1.5
		},
		link: {
			fontSize: 14,
			lineHeight: 1.5,
			fontWeight: 500,
			color: 'black',
			'&:hover': {
				fontSize: 12
			},
			'@media(680px)': {
				fontSize: 12
			},
			[theme.breakpoints.down('sm')]: {
				fontSize: 12
			}
		},
		link2: {
			fontSize: 14,
			lineHeight: 1.5,
			color: '#1976d2'
		},
		breadCrumbs: {
			display: 'flex',
			alignItems: 'center',
			position: 'relative',
			background: '#eee',
			color: 'black',
			padding: theme.spacing(1, 3),
			width: 'max-content',
			height: 44,
			borderRadius: 50
		},
		breadCrumbItem: {
			display: 'flex',
			alignItems: 'center',
			color: 'black',
			cursor: 'pointer',
			fontWeight: 500,
			textDecoration: 'underline',
			textTransform: 'capitalize',
			'& > *': {
				zIndex: 1,
				marginRight: theme.spacing(1)
			},
			'& > p': {
				marginRight: theme.spacing(0),
				fontWeight: 600
			}
		},
		breadCrumbLast: {
			textDecoration: 'none'
		}
	});

export default Styles;
