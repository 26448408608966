import * as React from 'react';

export interface RefProps<T = any> {
	innerRef?: React.Ref<T>;
}
/**
 *
 * @param {any} Component - Input Component.
 * @returns {Component} Component.
 */
export const withRef = <Props extends RefProps>(Component: React.ComponentClass<Props>) => {
	type ComponentInstance = InstanceType<typeof Component>;
	return React.forwardRef<ComponentInstance, Props>((props, ref) => (
		<Component innerRef={ref} {...props} />
	));
};
