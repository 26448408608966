import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import { EventEmitter } from 'events';
import { MDFEVENTS } from '../constants/events';

export class MDFEventEmitter {
	private _origin = window.location.origin;

	private _onChange = (e: MessageEvent) => {
		const Type = get(e.data, 'type', '');
		const Payload = get(e.data, 'payload', {});
		if (!isEmpty(Payload) && Type === MDFEVENTS) {
			this.onChange(Payload);
		}
	};

	public onChange: EventListener = (data) => {};

	constructor() {
		window.addEventListener('message', this._onChange, false);
	}

	public emit(type: string, data: any) {
		window.postMessage({ type: MDFEVENTS, payload: { type, data } }, this._origin);
	}
}

export const MDFEvent = new EventEmitter();
export const AuthEvents = new EventEmitter();
export const DBEvents = new EventEmitter();
export const NetworkEvents = new EventEmitter();
