import { Observable } from 'rxjs';
import omit from 'lodash/omit';
import compact from 'lodash/compact';
import get from 'lodash.get';
import set from 'lodash/set';
import jwt from 'jwt-decode';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Omit } from '../utils/generics';
import { AUTHSEREVER, REFRESHTOKEN } from '../constants/config';
import { TokenStorageDB } from '../database/tokenstorage';
import { APPLICATIONEVENTS, MDFLOGINEXPIRED, NOSESSION } from '../constants/events';
import { AuthEvents } from '../events';
import ValidationError from './error';
import { RequestOptions, URLConfig } from './types';
import Resources from '../constants/resource';

type ResponseType = Record<string, any>;
export interface ResponseError {
	config: any;
	request: any;
	response: {
		data: any;
		status: any;
		config: any;
		request: any;
		statusText: any;
		headers: any;
	};
	isAxiosError: boolean;
	toJSON: () => void;
}

/**
 *
 * MDFAjax Ajax wrapper on all API calls.
 * @constructor MDFAjax
 */
export class MDFAjax<URLS extends string> {
	private _extraOptions = ['secure'];

	private _options: RequestOptions = {
		timeout: 0
	};

	private _defaultOptions: RequestOptions = {
		timeout: 0
	};

	private _url = '';

	private _urlConfigs: URLConfig = {};

	private _domain = '';

	private _body: any = {};

	constructor(domain?: string, urlConfig?: URLConfig) {
		this._domain = domain || '';
		this._urlConfigs = urlConfig || {};
	}

	/**
	 * Create GET Ajax request
	 * @param {string} request AxiosRequestConfig
	 * @returns {Observable} Append Headers to the request
	 * @memberof MDFAjax
	 */
	private _fromRequest = (request: AxiosRequestConfig) =>
		new Observable<AxiosResponse>((o) => {
			// Create cancel token
			const source = axios.CancelToken.source();

			o.add(() => source.cancel('Operation canceled by the user.'));
			// append Headers

			this._appendHeaders()
				.then((options) => {
					axios({ ...request, ...options, cancelToken: source.token })
						.then((response) => {
							o.next(response);
							o.complete();
						})
						.catch((err) => {
							if (!axios.isCancel(err)) {
								o.error(err);
							}
							o.complete();
						});
				})
				.catch((error) => {
					o.error(error);
					o.complete();
				});

			// Fire axios data
		});

	/**
	 * Append MDF specific headers to the ajax call
	 * @returns {RequestOptions} Append Headers to the request
	 * @private
	 * @memberof MDFAjax
	 */
	private _appendHeaders = async () => {
		const allOptions = omit(this._options, this._extraOptions);
		const headers = get(allOptions, 'headers', {});
		if (this._options.secure === true) {
			await this._validateToken();
			const Token = await TokenStorageDB.getKey('accessToken');
			set(allOptions, 'headers', {
				...headers,
				Authorization: Token || ''
			});
		}
		return allOptions;
	};

	private _refreshUserToken = async (Token) => {
		try {
			const refreshResponse = await this.post(
				(AUTHSEREVER + REFRESHTOKEN) as any,
				{},
				{
					headers: {
						Authorization: Token
					}
				}
			).toPromise();
			if (refreshResponse.data) {
				return await this._storeRefreshToken(refreshResponse.data);
			}
		} catch (error) {
			const status = get(error, 'response.status', 500);
			if (status === 401) {
				AuthEvents.emit(MDFLOGINEXPIRED);
				throw new ValidationError(Resources.LoginSessionExpired, this._options);
			}
			return false;
		}
		return false;
	};

	private _validateToken = async () => {
		const AccessToken = await TokenStorageDB.getKey('accessToken');
		const RefreshToken = await TokenStorageDB.getKey('refreshToken');
		let expiry = 0;
		if (AccessToken) {
			const token = AccessToken.replace('Bearer ', '');
			const decodedToken = jwt(token);
			expiry = get(decodedToken, 'exp', 0);
			if (Date.now() / 1000 > expiry) {
				const refresh = await this._refreshUserToken(RefreshToken);
				if (!refresh) {
					return false;
				}
				return refresh;
			}
		} else {
			AuthEvents.emit(NOSESSION);
			throw new ValidationError(Resources.LoginSessionExpired, this._options);
		}
		return false;
	};

	private _storeRefreshToken = async (data) => {
		const accessToken = get(data, 'accessToken', '');
		const refreshToken = get(data, 'refreshToken', '');
		await TokenStorageDB.storeKeys([
			{
				key: 'accessToken',
				value: accessToken
			},
			{
				key: 'refreshToken',
				value: refreshToken
			}
		]);
		AuthEvents.emit(APPLICATIONEVENTS.TOKENCHANGED, {
			...data
		});
		return true;
	};

	/**
	 * Create GET Ajax request
	 * @param {string} url API url
	 * @param {RequestOptions} options - HTTP Request options
	 * @returns {Observable} Append Headers to the request
	 * @memberof MDFAjax
	 */
	public request = (options: RequestOptions) => {
		this._options = { ...this._defaultOptions, ...options };
		const { url } = options;
		this._url = this._generateURL(url as any);
		return this._fromRequest({
			...this._options,
			responseType: options.responseType || 'json'
		});
	};

	/**
	 * Create GET Ajax request
	 * @param {string} url API url
	 * @param {RequestOptions} options - HTTP Request options
	 * @returns {Observable} Append Headers to the request
	 * @memberof MDFAjax
	 */
	public get = (url: URLS, options?: RequestOptions) => {
		this._options = { ...this._defaultOptions, ...options };
		this._url = this._generateURL(url);
		return this._fromRequest({
			method: 'get',
			url: this._url as string,
			responseType: 'json'
		});
	};

	/**
	 * Create DELETE Ajax request
	 * @param {string} url API url
	 * @param {RequestOptions} options - HTTP Request options
	 * @returns {Observable} Append Headers to the request
	 * @memberof MDFAjax
	 */
	public delete = (url: URLS, options?: RequestOptions) => {
		this._options = { ...this._defaultOptions, ...options };
		this._url = this._generateURL(url);
		return this._fromRequest({ method: 'delete', url: this._url as string });
	};

	/**
	 * Create POST Ajax request
	 * @param {string} url API url
	 * @param {any} data - Data / Body payload
	 * @param {RequestOptions} options - HTTP Request options
	 * @returns {Observable} Append Headers to the request
	 * @memberof MDFAjax
	 */
	public post = (url: URLS, data: any, options?: Omit<RequestOptions, 'data'>) => {
		this._options = { ...this._defaultOptions, ...options };
		this._url = this._generateURL(url);
		this._body = data;
		return this._fromRequest({ method: 'POST', url: this._url as string, data: this._body });
	};

	/**
	 * Create PUT Ajax request
	 * @param {string} url API url
	 * @param {any} data - Data / Body payload
	 * @param {RequestOptions} options - HTTP Request options
	 * @returns {Promise<boolean>} Append Headers to the request
	 * @memberof MDFAjax
	 */
	public put = (url: URLS, data: any, options?: Omit<RequestOptions, 'data'>) => {
		this._options = { ...this._defaultOptions, ...options };
		this._url = this._generateURL(url);
		this._body = data;
		return this._fromRequest({ method: 'PUT', url: this._url as string, data: this._body });
	};

	/**
	 * Create PATCH Ajax request
	 * @param {string} url API url
	 * @param {any} data - Data / Body payload
	 * @param {RequestOptions} options - HTTP Request options
	 * @returns {Promise<boolean>} Append Headers to the request
	 * @memberof MDFAjax
	 */
	public patch = (url: URLS, data: any, options?: Omit<RequestOptions, 'data'>) => {
		this._options = { ...this._defaultOptions, ...options };
		this._url = this._generateURL(url);
		this._body = data;
		return this._fromRequest({
			method: 'PATCH',
			url: this._url as string,
			data: this._body
		});
	};

	private _generateURL = (urlName: URLS): string => {
		const url: any = get(this._urlConfigs, urlName, urlName);
		if (url === urlName) {
			return urlName as string;
		}
		const domain = url.domain || this._domain;
		const urlArray = compact([domain, url.base_url, url.verb || '', url.version || '']);
		return urlArray.join('');
	};
}

export const API = new MDFAjax();
export default API;
