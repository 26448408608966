const Default = 'Request cannot be completed at this time. Please try again or contact helpdesk';
export const Messages = {
	otp: {
		default: Default,
		401: 'Invalid OTP. Please enter valid OTP',
		403: 'Invalid OTP. Please enter valid OTP',
		400: 'Invalid request. Please check the information provided'
	},
	login: {
		default: Default,
		401: 'Invalid credentials or the user does not exist',
		404: 'Invalid credentials or the user does not exist',
		403: 'Too many invalid login attempts. Please retry after some time',
		400: 'Invalid details. Please check the information provided',
		InvalidLoginCountExceeded:
			'Your account has been blocked as you have exceeded the maximum number of login attempts. Please contact your administrator to unblock your account',
		InvalidUserCredentialsException:
			'You have one more attempt left to enter a correct password, post which your account will be blocked'
	},
	forgotPassword: {
		default: Default,
		401: 'Email does not exist, please provide a valid email address.',
		404: 'Email does not exist, please provide a valid email address.',
		403: 'Too many invalid forgot password attempts. Please retry after some time',
		400: 'Invalid details. Please check the information provided'
	}
};

export const ExceptionMessages = Messages;
