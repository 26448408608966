import { Theme, createStyles } from '@material-ui/core/styles';

/**
 *
 * @param {Theme} theme Material Theam.
 * @returns {Theme} Return Material theams.
 */
const Styles = (theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
			display: 'flex',
			flexDirection: 'column'
		},
		formBox: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',

			flexGrow: 1,
			'& > *': {
				width: '100%'
			}
		},
		otpTypeFrom: {
			marginLeft: theme.spacing(2)
		},
		title: {
			color: 'rgba(0, 0, 0, 0.85)',
			marginBottom: theme.spacing(2),
			textDecoration: 'underline',
			fontSize: '24px',
			letterSpacing: '2.14px',
			lineHeight: '32.68px',
			textAlign: 'center'
		},
		otpInput: {
			...theme.typography.body1,
			width: `${theme.spacing(6)}px !important`,
			height: theme.spacing(6),
			padding: theme.spacing(2)
		},
		otpBox: {
			display: 'flex',
			justifyContent: 'center',
			marginBottom: theme.spacing(2),
			marginTop: theme.spacing(2)
		},
		separator: {
			margin: `0 ${theme.spacing(1)}px`,
			fontSize: theme.spacing(4)
		},
		link: {
			color: '#0091FF',
			display: 'box',
			fontSize: theme.spacing(18 / 8),
			textAlign: 'center',
			marginBottom: theme.spacing(4),
			marginTop: theme.spacing(0),
			textDecoration: 'underline',
			cursor: 'pointer'
		},
		otpImage: {
			width: 140,
			height: 'auto',
			maxHeight: 140,
			margin: '0 auto',
			marginBottom: theme.spacing(1),
			display: 'block',
			objectFit: 'contain'
		},
		actions: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center'
		},
		caption: {
			textTransform: 'lowercase'
		}
	});

export default Styles;
