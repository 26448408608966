import * as React from 'react';
import { Box, withWidth, WithWidth } from '@material-ui/core';
import { BrowserRouter } from 'react-router-dom';
import { nanoid } from 'nanoid';
import Loadable from '@loadable/component';
import { MeraDmartBase, DeviceProvider, PageError, AjaxRequests } from '../components';
import { MeraDmartTheme } from '../constants/theme';
import HeaderMenu from '../components/HeaderMenu';

const Desktop = Loadable(() => import(/* webpackPrefetch: true */ '../pages/Desktop/Root'));
const Login = Loadable(() => import(/* webpackPrefetch: true */ './login'));

const { REACT_APP_ID = '' } = process.env;
interface State {
	// pass
	sample?: string;
}
/**
 * Application sample.
 * @returns {React.Component} compoent.
 */
class App extends React.Component<WithWidth, State> {
	/**
	 * Constructor
	 * @param {any} props - Properties.
	 * @returns {void} Contructor.
	 */
	constructor(props: WithWidth) {
		super(props);
		this.state = {};
	}

	componentDidMount = () => {
		const path = window.location.href;

		if (path.includes('download')) {
			localStorage.setItem(`d-redirect`, window.location.pathname);
		}
	};

	renderErrorState = () => {
		return (
			<Box>
				<HeaderMenu onlyHeader />
				<PageError />
			</Box>
		);
	};

	/**
	 * Render method
	 * @returns {JSX.Element} Jsx.
	 */
	renderMainApps = () => {
		// const { width } = this.props;
		// if (isWidthDown('md', width) && isWidthDown('sm', width)) {
		// 	return <>{this.renderErrorState()}</>;
		// }
		return (
			<PageError>
				<Login>
					<BrowserRouter>
						<Desktop />
					</BrowserRouter>
				</Login>
			</PageError>
		);
	};

	/**
	 * Render method
	 * @returns {JSX.Element} Jsx.
	 */
	render() {
		return (
			<MeraDmartBase
				appName={`MDF-UI-${REACT_APP_ID}-${nanoid(6)}`}
				theme={MeraDmartTheme as any}
			>
				<AjaxRequests />
				<DeviceProvider>{this.renderMainApps()}</DeviceProvider>
			</MeraDmartBase>
		);
	}
}

export default withWidth()(App);
