import * as React from 'react';
import { WithStyles, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import Style from './style';
import { generateStyle } from '../../utils/functions';

interface Props extends WithStyles<typeof Style>, React.HTMLAttributes<HTMLDivElement> {
	children?: React.ReactNode;
	loading?: boolean;
}
/**
 *
 */
class LoadingButton extends React.PureComponent<Props> {
	/**
	 * Render method
	 * @returns {JSX.Element} Jsx.
	 */
	public render() {
		const { classes, children, loading, className, ...rest } = this.props;
		return (
			<div className={clsx(classes.root, className)} {...rest}>
				{children}
				{loading && <CircularProgress size={24} className={classes.buttonProgress} />}
			</div>
		);
	}
}

export default generateStyle(Style, 'LoadingButton')(LoadingButton);
