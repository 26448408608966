import { Theme, createStyles } from '@material-ui/core/styles';

/**
 *
 * @param {Theme} theme Material Theam.
 * @returns {Theme} Return Material theams.
 */
const Styles = (theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
			display: 'flex',
			flexDirection: 'column'
		},
		title: {
			color: 'rgba(0, 0, 0, 0.85)',
			marginBottom: theme.spacing(4),
			textDecoration: 'underline',
			fontSize: '24px',
			letterSpacing: '2.14px',
			lineHeight: '32.68px',
			textAlign: 'center'
		},
		form: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			flexGrow: 1,
			flexDirection: 'column',
			'& > div': {
				width: '100%'
			}
		},
		submit: {
			marginLeft: 'auto',
			width: '100%',
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			marginTop: theme.spacing(2)
		},
		links: {
			color: '#0091FF',
			marginLeft: 'auto'
		},
		displayFlex: {
			display: 'flex',
			justifyContent: 'space-between'
		},
		logout: {
			marginRight: theme.spacing(2)
		}
	});

export default Styles;
