import { API } from '../network';
import { USERINFO } from '../constants/config';

interface userSubSectionInfo {
	[name: string]: Array<string>;
}
type UserDetails = Array<string | userSubSectionInfo>;
export interface userInfoConfig {
	user: UserDetails;
	[name: string]: UserDetails;
}
export const DEFAULTUSERINFO: userInfoConfig = {
	user: [
		'firstName',
		'email',
		'lastName',
		'mobile',
		'siteId',
		'twoFactorAuthEnabled',
		{ manager: ['firstName', 'email', 'lastName', 'mobile', 'siteId', 'twoFactorAuthEnabled'] }
	],
	roles: ['name'],
	userApps: [
		'applicationCode',
		'applicationName',
		'applicationIcon',
		'applicationIndex',
		'metaDescription',
		'applicationTitle',
		'applicationUrl',
		'isActive'
	]
};
export const getUserInfo = async (
	url: string,
	token: string,
	userInfoConfig: userInfoConfig = DEFAULTUSERINFO
) => {
	let userData: Record<string, any> = {};
	const response = await API.get(url + USERINFO, {
		secure: true
	}).toPromise();
	if (response.data) {
		const { data } = response;
		userData = { ...data };
	}
	return userData;
};
