import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {
	WithStyles,
	CircularProgress,
	Collapse,
	Box,
	AppBar,
	Toolbar,
	Dialog,
	DialogContent,
	Typography
} from '@material-ui/core';
import jwt from 'jwt-decode';
import clsx from 'clsx';
import { BrowserRouter, Switch, Route, withRouter, RouteComponentProps } from 'react-router-dom';
import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import isNull from 'lodash/isNull';
import { Warning } from '@material-ui/icons';
import { UserProps, INITIALUSERDATA, UserInfoProps } from './types';
import LoginForm from '../LoginForm';
import Otp from '../OTPForm';
import Style from './style';
import { MDFAjax } from '../../network';
import { LOGINCONFIG } from '../../constants/variables';
import { MDFDB } from '../../db';
import { TokenStorageDB, TokenData } from '../../database/tokenstorage';
import { AuthEvents } from '../../events';
import {
	MDFLOGOUT,
	MDFEXPIRED,
	APPLICATIONEVENTS,
	MDFLOGINEXPIRED,
	NOSESSION
} from '../../constants/events';
import { AUTHSEREVER } from '../../constants/config';
import { generateStyle, getRelativePath, readAccessToken } from '../../utils/functions';
import { getUserInfo, userInfoConfig, DEFAULTUSERINFO } from '../../utils/userinfo';
import ForgotPasswordForm from '../ForgotPasswordForm';
import ResetPasswordForm from '../ResetPasswordForm';
import { RefreshTokenSuccess } from '../../utils/events';
import Notifications from '../Notifications';
import Resources from '../../constants/resource';

const Logo = getRelativePath('images/logo-mera.jpg');
export const UserContext = React.createContext<UserProps>(INITIALUSERDATA);
type Environments = 'dev' | 'canary' | 'production';
interface ProviderProps extends WithStyles<typeof Style>, RouteComponentProps {
	children?: React.ReactNode;
	silent?: boolean;
	userParameters?: userInfoConfig;
	environment?: Environments;
	environmentURL?: Record<Environments, string>;
}
interface ProviderState {
	context: UserProps;
	isInitialized: boolean;
	isLoggedIn: boolean;
	loginExpired: boolean;
	previousUsername: string;
	showPanel: number;
	expired: boolean;
	userReference: Record<string, any>;
}
/**
 * User Provider
 */
export class LoginProvider extends React.Component<ProviderProps, ProviderState> {
	private _api: MDFAjax<keyof typeof LOGINCONFIG>;

	static defaultProps: Partial<ProviderProps> = {
		silent: false,
		environment: 'dev',
		environmentURL: {
			dev: AUTHSEREVER,
			canary: AUTHSEREVER,
			production: AUTHSEREVER
		},
		userParameters: { ...DEFAULTUSERINFO }
	};

	/**
	 *
	 * @param {object} props Properties
	 */
	constructor(props) {
		super(props);
		const { environmentURL, environment } = this.props;
		const url = get(environmentURL as any, environment as any, '');
		this._api = new MDFAjax<keyof typeof LOGINCONFIG>(url, LOGINCONFIG);
		this.state = {
			context: { ...INITIALUSERDATA },
			isInitialized: false,
			previousUsername: '-',
			expired: false,
			isLoggedIn: false,
			loginExpired: false,
			showPanel: 0,
			userReference: {}
		};
	}

	/**
	 * Component did Mount.
	 * @returns {void}
	 */
	componentDidMount = async () => {
		const { location, history } = this.props;
		const password = location.pathname.startsWith('/passwor') ? location.pathname : '/password';
		const pathNames = [
			'/forgotPassword',
			'/forgotPassword/',
			'/password',
			'/password/',
			password
		];

		if (pathNames.includes(location.pathname)) {
			await this.clearSession();
		} else {
			await this.sessionValidation();
		}
		AuthEvents.on(MDFLOGOUT, async (d) => {
			this.setState({ userReference: {} }, async () => {
				await this.clearSession(true);
				history.replace('/');
			});
		});
		AuthEvents.on(MDFEXPIRED, async (d) => {
			this.setState({ expired: true }, async () => {
				await this.clearSession(true);
				history.replace('/');
			});
		});
		AuthEvents.on(MDFLOGINEXPIRED, async (d) => {
			const { isLoggedIn, loginExpired } = this.state;
			const AccessToken = await TokenStorageDB.getKey('accessToken');
			if (isEmpty(AccessToken)) {
				this.setState({ expired: true }, async () => {
					await this.clearSession();
				});
				return true;
			}
			const tokenDetails = readAccessToken(AccessToken);
			const user = get(tokenDetails, 'user', '');
			if (isLoggedIn && !loginExpired) {
				this.setState({ loginExpired: true, previousUsername: user }, async () => {
					await this.clearSession(true, true);
				});
			}
			return true;
		});
		AuthEvents.on(NOSESSION, async (d) => {
			const { isLoggedIn } = this.state;
			if (isLoggedIn) {
				this.setState({ expired: true, isLoggedIn: false }, async () => {
					await this.clearSession();
				});
			}
			return true;
		});
		setTimeout(() => {
			window.addEventListener(APPLICATIONEVENTS.REFRESHTOKEN, this.validateToken);
		}, 1000);

		window.addEventListener(APPLICATIONEVENTS.APIEXCEPTION, (evt) => {
			const error = get(evt, 'detail', {});
			const status = get(error, 'response.status', 0);
			const childStatus = get(error, 'status', 0);
			// if (status === 401 || childStatus === 401) {
			// AuthEvents.emit(MDFEXPIRED);
			// }
		});

		this.setState({ isInitialized: true, showPanel: 0 }, () => {});
	};

	componentDidUpdate = async (prevProps: ProviderProps, prevState: ProviderState) => {
		const prevLocation = prevProps.location;
		const { location } = this.props;
		const prevIsinitialized = prevState.isInitialized;
		const { isInitialized } = this.state;
		const password = location.pathname.startsWith('/passwor') ? location.pathname : '/password';
		const pathNames = [
			'/forgotPassword',
			'/forgotPassword/',
			'/password',
			'/password/',
			password
		];
		if (prevLocation.pathname !== location.pathname) {
			if (pathNames.includes(location.pathname)) {
				await this.clearSession();
			}
		}
		if (prevIsinitialized !== isInitialized && isInitialized) {
			const loading = document.getElementById('loading');
			if (loading) {
				loading.remove();
			}
		}
	};

	onSlideChange = (index) => {
		this.setState({ showPanel: index });
	};

	onLoginSuccess = (data) => {
		const userReference = get(data, 'userReference', {});
		const accessToken = get(data, 'accessToken', '');
		if (!isEmpty(accessToken)) {
			this.setState({ userReference: {} }, () => {
				this.initiateSession(data);
				// this.forceUpdate();
			});
		} else {
			this.setState({ userReference, showPanel: 1 });
		}
	};

	onReset = () => {
		this.setState({ userReference: {}, showPanel: 0 });
	};

	onOTPSuccess = async (data) => {
		const userReference = get(data, 'userReference', {});
		const oneTimePassword = get(data, 'oneTimePassword', false);
		if (oneTimePassword) {
			this.setState({
				userReference: { ...userReference, mode: 'update_password', apiresponse: data },
				showPanel: 0
			});
		} else {
			this.setState({ userReference: {} }, () => {
				this.initiateSession(data);
			});
		}
	};

	clearSession = async (reload = false, onlyCleanDb = false) => {
		const { silent, history, location } = this.props;
		try {
			await this._api.post('logout', {}, { secure: true }).toPromise();
		} catch (error) {
			// pass
		}
		await MDFDB.clearAllDbs();
		await TokenStorageDB.clearDB();
		const password = location.pathname.startsWith('/passwor') ? location.pathname : '/password';
		const pathNames = [
			'/forgotPassword',
			'/forgotPassword/',
			'/password',
			'/password/',
			password
		];
		this.setState(
			{ isLoggedIn: onlyCleanDb, showPanel: 0, isInitialized: true, userReference: {} },
			() => {
				if (silent) {
					window.location.reload();
				}
				if (!pathNames.includes(location.pathname) && !reload) {
					history.replace('/');
				}
			}
		);
	};

	initiateSession = async (data) => {
		const accessToken = get(data, 'accessToken', '');
		const refreshToken = get(data, 'refreshToken', '');
		await TokenStorageDB.storeKeys([
			{
				key: 'accessToken',
				value: accessToken
			},
			{
				key: 'refreshToken',
				value: refreshToken
			}
		]);
		await this.validateToken();
		this.setState({
			isLoggedIn: true,
			showPanel: 0,
			previousUsername: '',
			loginExpired: false
		});
		window.onbeforeunload = (e) => {
			const message = Resources.NavigateAway;
			e.returnValue = message;
			return message;
		};
		return true;
	};

	sessionValidation = async () => {
		const AccessToken = await TokenStorageDB.getKey('accessToken');
		const valid = await this.validateToken();
		let isLoggedIn = false;
		const data: Record<string, any> = {};
		if (valid) {
			data.expired = false;
			isLoggedIn = true;
		} else if (!isEmpty(AccessToken) && !valid) {
			data.expired = true;
			isLoggedIn = false;
			await this.clearSession();
		}
		this.setState(
			{
				isLoggedIn,
				...data
			},
			() => {
				if (!isLoggedIn) {
					this.clearSession();
				}
			}
		);
		return true;
	};

	validateToken = async () => {
		const { environmentURL, environment, userParameters } = this.props;
		const AccessToken = await TokenStorageDB.getKey('accessToken');
		const RefreshToken = await TokenStorageDB.getKey('refreshToken');
		const url = get(environmentURL as any, environment as any, '');
		if (AccessToken) {
			try {
				const userInfo = await getUserInfo(url, AccessToken, userParameters);
				if (isEmpty(userInfo) || isNull(userInfo)) {
					const refresh = await this.refreshToken(RefreshToken);
					if (!refresh) {
						this.clearSession();
						return false;
					}
					return refresh;
				}
				const AccessTokenNew = await TokenStorageDB.getKey('accessToken');
				const token = AccessTokenNew.replace('Bearer ', '');
				const decodedToken = jwt(token);
				const user: UserInfoProps = {
					...decodedToken,
					authenticated: !isEmpty(AccessTokenNew),
					...userInfo
				};
				const ContexToken: TokenData = {
					accessToken: AccessTokenNew,
					refreshToken: RefreshToken
				};
				this.setState(
					{
						context: { user, token: ContexToken }
					},
					() => {
						RefreshTokenSuccess({ user, token: ContexToken });
					}
				);
				return true;
			} catch (error) {
				const status = get(error, 'response.status', 500);
				if (status === 401) {
					const refresh = await this.refreshToken(RefreshToken);
					return refresh;
				}
			}
		} else {
			const { isLoggedIn } = this.state;
			if (isLoggedIn) {
				this.sessionExpired();
			}
			return false;
		}
		return false;
	};

	sessionExpired = async () => {
		this.setState({ expired: true }, () => {
			this.clearSession();
		});
	};

	refreshToken = async (refreshToken) => {
		try {
			const refreshResponse = await this._api
				.post(
					'refresh',
					{},
					{
						headers: {
							Authorization: refreshToken
						}
					}
				)
				.toPromise();
			if (refreshResponse.data) {
				return await this.storeRefreshToken(refreshResponse.data);
			}
		} catch (error) {
			this.clearSession();
			return false;
		}
		return true;
	};

	storeRefreshToken = async (data) => {
		const accessToken = get(data, 'accessToken', '');
		const refreshToken = get(data, 'refreshToken', '');
		await TokenStorageDB.storeKeys([
			{
				key: 'accessToken',
				value: accessToken
			},
			{
				key: 'refreshToken',
				value: refreshToken
			}
		]);
		this.setState({ isLoggedIn: true, showPanel: 0 });
		return true;
	};

	/**
	 * renderChild
	 * @returns {JSX.Element} Jsx.
	 */
	renderLogin = (): JSX.Element => {
		const { classes, silent, history } = this.props;
		const { isInitialized, showPanel, userReference, expired } = this.state;
		if (silent) {
			return (
				<div>
					<></>
				</div>
			);
		}
		return (
			<div
				className={clsx(classes.root, {
					[classes.rootBackground]: isInitialized
				})}
			>
				{isInitialized && (
					<AppBar className={classes.appBarRoot} elevation={2}>
						<Toolbar>
							<img
								src={Logo}
								alt="Logo"
								role="presentation"
								onClick={() => history.replace('/')}
								className={classes.appBarLogo}
							/>
						</Toolbar>
					</AppBar>
				)}
				<div className={classes.loginContainer}>
					<Box className={classes.logoContainer}>
						<img
							src={Logo}
							alt="Logo"
							role="presentation"
							className={classes.logo}
							onClick={() => history.replace('/')}
						/>
					</Box>
					<Collapse in={!isInitialized}>
						<CircularProgress
							variant="indeterminate"
							size={24}
							thickness={5}
							color="primary"
						/>
					</Collapse>
					<Collapse in={isInitialized}>
						<Box className={classes.pageContainer}>
							<Box className={classes.page}>
								<Switch>
									<Route path="/forgotPassword" exact>
										<ForgotPasswordForm api={this._api} />
									</Route>
									<Route path="/password" exact>
										<ResetPasswordForm api={this._api} />
									</Route>
									<Route path="/password/:token" exact>
										<ResetPasswordForm api={this._api} />
									</Route>
									<Route path="/setPassword/:token" exact>
										<ResetPasswordForm api={this._api} />
									</Route>
									<Route path="*">
										{showPanel === 0 && (
											<LoginForm
												api={this._api}
												userReference={userReference}
												onSuccess={this.onLoginSuccess}
											/>
										)}
										{showPanel === 1 && (
											<Otp
												userPreference={userReference}
												api={this._api}
												onReset={this.onReset}
												onSuccess={this.onOTPSuccess}
											/>
										)}
									</Route>
								</Switch>
							</Box>
							<Box className={classes.footer}>
								{/* <img
									src={sslSecurity}
									className={classes.securityLogo}
									alt="SSL security"
								/> */}
							</Box>
						</Box>
					</Collapse>
				</div>
			</div>
		);
	};

	private _closeSnackbar = () => {
		this.setState({ expired: false });
	};

	/**
	 * renderChild
	 * @returns {JSX.Element} Jsx.
	 */
	renderChild = (): JSX.Element => {
		const { children, classes } = this.props;
		const {
			isLoggedIn,
			isInitialized,
			loginExpired,
			previousUsername,
			showPanel,
			userReference
		} = this.state;

		if (isLoggedIn && isInitialized) {
			return (
				<div>
					{!loginExpired && children}
					{loginExpired && (
						<Dialog open={loginExpired} classes={{ paper: classes.expiredPaper }}>
							<DialogContent style={{ paddingBottom: 24 }}>
								<Typography align="center">
									<Warning style={{ color: '#FF9800' }} />
								</Typography>
								<Typography align="center">{Resources.SessionExpired}</Typography>
								<Typography variant="body2" paragraph>
									{Resources.SessionExpiredPopupAlert}
								</Typography>
								{showPanel === 0 && (
									<LoginForm
										api={this._api}
										onSuccess={this.onLoginSuccess}
										username={previousUsername}
									/>
								)}
								{showPanel === 1 && (
									<Otp
										userPreference={userReference}
										api={this._api}
										onReset={this.onReset}
										onSuccess={this.onOTPSuccess}
										username={previousUsername}
									/>
								)}
							</DialogContent>
						</Dialog>
					)}
				</div>
			);
		}
		return ReactDOM.createPortal(this.renderLogin(), document.body);
	};

	/**
	 * Render method
	 * @returns {JSX.Element} Jsx.
	 */
	render(): JSX.Element {
		const { context } = this.state;
		return (
			<UserContext.Provider value={{ ...context }}>{this.renderChild()}</UserContext.Provider>
		);
	}
}

const LoginComponent = generateStyle(Style, 'MDFLogin')(withRouter(LoginProvider));

export const MDFLogin = (props: Partial<ProviderProps>) => {
	const { children, ...rest } = props;
	return (
		<BrowserRouter>
			<Notifications />
			<LoginComponent {...rest}>{children}</LoginComponent>
		</BrowserRouter>
	);
};
