import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import * as singleSpa from 'single-spa';
import CreateStore from './store';
import * as serviceWorker from './serviceWorker';
import App from './app';

const { store, persistor } = CreateStore();

/**
 * Initialize the main application.
 * @param {any} cordova - cordova is true.
 * @returns {void} Init.
 */
const init = (cordova = false) => {
	ReactDOM.render(
		<Provider store={store}>
			<App />
		</Provider>,
		document.getElementById('root')
	);
};

if (window.location.search.includes('cordova=yes')) {
	document.addEventListener(
		'deviceready',
		() => {
			init(true);
		},
		false
	);
} else {
	init();
}

singleSpa.start();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
