import { AjaxError } from 'rxjs/ajax';
import { nanoid } from 'nanoid';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import { FileState, FileActions, GetFiles, GET_FILE, GETFILE } from './types';
import { ActionState } from '../../constants/api';

const INITIAL_GET_FILE_STATE: GetFiles = {
	id: nanoid(6),
	getFile: ActionState.NONE,
	url: '',
	errorData: {}
};
const INITIAL_STATE: FileState = {
	getFile: ActionState.NONE,
	getFiles: {}
};
/**
 * Reducer.
 * @param {any} state -  state.
 * @param {any} action - One of actions
 * @returns {any}  state object.
 */
const FileReducer = (state: FileState = INITIAL_STATE, action: FileActions): FileState => {
	switch (action.type) {
		case GET_FILE.name: {
			const { id } = action.payload as GETFILE;
			const stateFile = cloneDeep(get(state.getFiles, id, INITIAL_GET_FILE_STATE));
			stateFile.id = id;
			stateFile.getFile = ActionState.LOADING;
			return {
				...state,
				getFiles: {
					...state.getFiles,
					[id]: {
						...state.getFiles[id],
						...stateFile
					}
				},
				getFile: ActionState.LOADING
			};
		}
		case GET_FILE.clear: {
			const { id } = action.payload;
			const stateFile = cloneDeep(get(state.getFiles, id, INITIAL_GET_FILE_STATE));
			stateFile.getFile = ActionState.NONE;
			stateFile.url = '';
			stateFile.errorData = {};
			return {
				...state,
				getFiles: {
					...state.getFiles,
					[id]: {
						...state.getFiles[id],
						...stateFile
					}
				},
				getFile: ActionState.NONE
			};
		}
		case GET_FILE.success: {
			const { data, request } = action.payload;
			const { id } = action.meta.payload;
			const stateFile = cloneDeep(get(state.getFiles, id, INITIAL_GET_FILE_STATE));
			const contentType = request.getResponseHeader('Content-Type');
			stateFile.getFile = ActionState.SUCCESS;
			const blob = new Blob([data], { type: contentType as string });
			const url = window.URL.createObjectURL(blob);
			stateFile.url = url;
			stateFile.blob = request.getResponseHeader('Content-Type');
			return {
				...state,
				getFiles: {
					...state.getFiles,
					[id]: {
						...state.getFiles[id],
						...stateFile
					}
				},
				getFile: ActionState.SUCCESS
			};
		}
		case GET_FILE.failure: {
			const response = action.payload as AjaxError;
			const { id } = action.meta.payload;
			const stateFile = cloneDeep(get(state.getFiles, id, INITIAL_GET_FILE_STATE));
			stateFile.getFile = ActionState.FAILURE;
			stateFile.errorData = response;
			return {
				...state,
				getFiles: {
					...state.getFiles,
					[id]: {
						...state.getFiles[id],
						...stateFile
					}
				},
				getFile: ActionState.FAILURE
			};
		}
		default:
			return state;
	}
};

export default FileReducer;
