import { ofType, ActionsObservable } from 'redux-observable';
import { map, mergeMap, catchError, takeUntil } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
import { from } from 'rxjs/internal/observable/from';
import { Ajax } from '../../utils/events';
import { GET_FILE } from './types';
import { Actions } from '../Home/types';

const { REACT_APP_API_URL = '', REACT_APP_API_GET_FILE = '' } = process.env;
const FILE_API = REACT_APP_API_URL + REACT_APP_API_GET_FILE;

export const GetFileEpic: any = (action$: ActionsObservable<Actions>) =>
	action$.pipe(
		ofType(GET_FILE.name),
		mergeMap((action) => {
			const ActionPayload = action.payload;
			return from(
				Ajax({
					method: 'GET',
					url: `${FILE_API}${ActionPayload.url}`,
					responseType: 'blob',
					secure: true
				})
			).pipe(
				map((response) => GET_FILE.successAction(response, action)),
				catchError((error) => of(GET_FILE.failureAction(error, action))),
				takeUntil(action$.pipe(ofType(GET_FILE.cancel)))
			);
		})
	);
export default GetFileEpic;
