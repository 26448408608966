import * as React from 'react';
import { WithStyles, Container, withWidth, WithWidth, isWidthUp } from '@material-ui/core';
import Style from './style';
import { generateStyle } from '../../utils/functions';

interface Props extends WithStyles<typeof Style>, WithWidth, React.HTMLAttributes<HTMLDivElement> {
	// props
}
/**
 *
 */
class PageContainer extends React.PureComponent<Props> {
	/**
	 * Render method
	 * @returns {JSX.Element} Jsx.
	 */
	public render() {
		const { classes, children, width, ...rest } = this.props;
		let maxWidth: any = 'md';
		if (isWidthUp('md', width) && width !== 'md') {
			maxWidth = 'lg';
		}
		return (
			<Container maxWidth={maxWidth} className={classes.root} {...rest}>
				{children as any}
			</Container>
		);
	}
}

export default generateStyle(Style, 'MDFPageContainer')(withWidth()(PageContainer));
