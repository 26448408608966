// import PBKDF2 from 'crypto-js/pbkdf2';
// import aes from 'crypto-js/rabbit';
// import utf8 from 'crypto-js/enc-utf8';
import { SECRETKEY } from '../constants/config';

const { REACT_APP_SECRET_KEY = SECRETKEY } = process.env;

export class Encryption {
	private _masterKey = REACT_APP_SECRET_KEY;

	private _privateKey = '';

	private _keySize = 256 / 32;

	constructor(key = REACT_APP_SECRET_KEY) {
		this._privateKey = this._generatePrivateKey(key);
	}

	private _generatePrivateKey(data: string) {
		// return PBKDF2(data, this._masterKey, {
		// 	keySize: this._keySize
		// }).toString();
		const keyValue = this._privateKey;

		return data;
	}

	public hashData(data: string) {
		// return PBKDF2(data, this._privateKey, {
		// 	keySize: this._keySize
		// }).toString();
		const keyValue = this._privateKey;

		return data;
	}

	// Cipher _encrypt function
	public encrypt = (value: any, key = '') => {
		if (!value) {
			return value;
		}
		return this._encrypt(JSON.stringify(value), key).toString();
	};

	private _encrypt = (value: any, key = '') => {
		// return aes.encrypt(value, this._privateKey + key).toString();
		const keyValue = this._privateKey;
		return value;
	};

	// Cipher _decrypt function
	public decrypt = (value: any, key = '') => {
		if (!value) {
			return value;
		}
		const d = this._decrypt(value, key);
		return JSON.parse(d);
	};

	private _decrypt = (value: any, key = '') => {
		const keyValue = this._privateKey;
		// return aes.decrypt(value, this._privateKey + key).toString(utf8);
		return value;
	};
}
const encryption = new Encryption();
export const MDFEncryption = new Encryption();
export default encryption;
