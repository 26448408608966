import CreateEventName from '../utils/CreateEventName';

export const MDFLOGOUT = 'MDFLOGOUT';
export const MDFEXPIRED = 'MDFEXPIRED';
export const MDFLOGINEXPIRED = 'MDFLOGINEXPIRED';
export const NOSESSION = 'NOSESSION';
export const MDFLOGIN = 'MDFLOGIN';
export const MDFDBKEYCHANGE = 'MDFDBKEYCHANGE';
export const MDFDBCHANGE = 'MDFDBCHANGE';
export const MDFDBCLEAR = 'MDFDBCLEAR';
export const MDFDBKEYREMOVE = 'MDFDBKEYREMOVE';
export const MDFEVENTS = 'MDFEVENTS';

export enum APPLICATIONEVENTS {
	REFRESHTOKEN = 'REFRESHTOKEN',
	REFRESHUSER = 'REFRESHUSER',
	APIEXCEPTION = 'APIEXCEPTION',
	NOTIFICATION = 'NOTIFICATION',
	REFRESHTOKENCHANGED = 'REFRESHTOKENCHANGED',
	TOKENCHANGED = 'TOKENCHANGED',
	APIREQUEST = 'APIREQUEST'
}
export enum APPLICATIONEVENTSSUCCESS {
	REFRESHTOKENSUCCESS = 'REFRESHTOKENSUCCESS',
	REFRESHTOKENERROR = 'REFRESHTOKENERROR',
	REFRESHUSERSUCCESS = 'REFRESHTOKENSUCCESS',
	REFRESHTOKENCHANGEDSUCEESS = 'REFRESHTOKENCHANGEDSUCEESS'
}

export const MDFEvents = {
	ApiRequest: new CreateEventName('ApiRequest')
};
