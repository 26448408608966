class CreateEventName {
	public name = '';

	public success = '';

	public failure = '';

	constructor(name = '') {
		this.name = `Event/${name}`;
		this.success = `${this.name}/SUCCESS`;
		this.failure = `${this.name}/FAILURE`;
	}
}

export default CreateEventName;
