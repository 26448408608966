import { Theme, createStyles } from '@material-ui/core/styles';
import { getRelativePath } from '../../utils/functions';
/**
 *
 * @param {Theme} theme Material Theam.
 * @returns {Theme} Return Material theams.
 */
const Styles = (theme: Theme) =>
	createStyles({
		root: {
			position: 'fixed',
			top: 0,
			zIndex: 1399,
			left: 0,
			width: '100vw',
			height: '100vh',
			overflowY: 'scroll',
			background: 'white',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center'
		},
		rootBackground: {
			'&::after': {
				content: '" "',
				background: `url(${getRelativePath(
					'images/background.webp'
				)}) no-repeat center center fixed`,
				top: 0,
				left: 0,
				bottom: 0,
				right: 0,
				position: 'absolute',
				backgroundSize: 'cover',
				zIndex: -1
			}
		},
		loginContainer: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			position: 'relative'
		},
		pageContainer: {
			borderRadius: 4,
			boxShadow: theme.shadows[2],
			margin: theme.spacing(2),
			marginTop: theme.spacing(49 / 8),
			display: 'flex',
			flexDirection: 'column',
			border: '1.5px solid',
			backgroundColor: 'white',
			borderColor: '#046D3954',
			[theme.breakpoints.up('xs')]: {
				width: '100%',
				minWidth: 445,
				maxWidth: 445
			},
			[theme.breakpoints.down('xs')]: {
				marginTop: theme.spacing(1),
				width: '100%',
				minWidth: '90vw',
				maxWidth: '90vw'
			}
		},
		appBarLogo: {
			width: '74px'
		},
		logo: {
			width: '162px',
			height: '66px',
			cursor: 'pointer'
		},
		logoContainer: {
			display: 'flex',
			justifyContent: 'center',
			position: 'relative',
			width: '100%'
		},
		backButton: {
			position: 'absolute',
			top: '50%',
			left: 0,
			transform: 'translateY(-50%)'
		},
		swipableContainer: {
			[theme.breakpoints.up('xs')]: {
				width: '100%'
			},
			[theme.breakpoints.down('xs')]: {
				width: '100%'
			}
		},
		footer: {
			padding: theme.spacing(2),
			display: 'flex',
			justifyContent: 'flex-end'
		},
		securityLogo: {
			height: 30,
			marginRight: theme.spacing(1),
			'&:last-of-type': {
				marginRight: 0
			}
		},
		page: {
			flexGrow: 1,
			display: 'flex',
			flexDirection: 'column',
			padding: theme.spacing(2),
			paddingLeft: theme.spacing(6),
			paddingRight: theme.spacing(6),
			[theme.breakpoints.down('xs')]: {
				paddingLeft: theme.spacing(2),
				paddingRight: theme.spacing(2)
			}
		},
		appBarRoot: {
			color: 'white',
			background: 'white',
			'& > div': {
				maxHeight: '56px !important',
				minHeight: '56px !important',
				Height: '56px !important'
			}
		},
		expiredPaper: {
			width: 540
		}
	});

export default Styles;
