import * as React from 'react';
import {
	Paper,
	WithStyles,
	Box,
	Typography,
	withWidth,
	WithWidthProps,
	isWidthDown,
	Snackbar,
	Button
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Style from './style';
import { generateStyle, getRelativePath } from '../../utils/functions';
import BreadCrumb from '../BreadCrumb';
import PageContainer from '../PageContainer';
import Resources from '../../constants/resource';

export interface Props extends WithStyles<typeof Style>, WithWidthProps {
	//
	children?: React.ReactNode;
	show?: boolean;
	showBreadCrumb?: boolean;
	image?: string;
	message?: string;
	type?: 'screenWidth' | 'alert' | '';
	onClick?: (e) => void;
}
enum Continue {
	NotSet,
	No,
	Yes
}
interface State {
	error: boolean;
	continueInSmallScreen: Continue;
	initialized: boolean;
	isPrinting: boolean;
	closeMediumScreenWarning: boolean;
	type?: 'screenWidth' | 'alert' | '';
}
/**
 *
 */
class PageError extends React.Component<Props, State> {
	static defaultProps: Partial<Props> = {
		image: getRelativePath('images/desktop.png'),
		message: Resources.ResolutionAlert,
		showBreadCrumb: false,
		type: 'screenWidth'
	};

	constructor(props: Props) {
		super(props);
		this.state = {
			error: false,
			continueInSmallScreen: Continue.NotSet,
			isPrinting: false,
			initialized: false,
			closeMediumScreenWarning: false,
			type: 'screenWidth'
		};
	}

	onBeforePrint = () => {
		this.setState({ isPrinting: true });
	};

	onAfterPrint = () => {
		this.setState({ isPrinting: false });
	};

	onClose = async () => {
		this.setState({ closeMediumScreenWarning: true });
	};

	onContinue = async () => {
		this.setState({ continueInSmallScreen: Continue.Yes });
	};

	onReject = async () => {
		this.setState({ continueInSmallScreen: Continue.No });
	};

	componentDidMount = async () => {
		this.setState({
			initialized: true
		});
		window.onbeforeprint = this.onBeforePrint;
		window.onafterprint = this.onAfterPrint;
	};

	static getDerivedStateFromProps(props: Props, state: State) {
		if (props.show) {
			return {
				error: props.show,
				type: props.type
			};
		}

		if (isWidthDown('md', props.width!) && isWidthDown('sm', props.width!)) {
			return {
				error: true,
				type: 'alert'
			};
		}
		if (
			isWidthDown('md', props.width!) &&
			props.type === 'screenWidth' &&
			!state.closeMediumScreenWarning
		) {
			return {
				error: false,
				type: 'screenWidth'
			};
		}
		return {
			error: false,
			type: ''
		};
	}

	renderAlert = () => {
		const { classes } = this.props;
		const { closeMediumScreenWarning, isPrinting } = this.state;

		if (closeMediumScreenWarning || isPrinting) {
			return null;
		}
		return (
			<Snackbar
				open
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				classes={{ root: classes.snackbarRoot }}
			>
				<Alert variant="standard" severity="warning" onClose={this.onClose} elevation={2}>
					<strong style={{ textAlign: 'justify' }}> {Resources.ResolutionAlert}</strong>
				</Alert>
			</Snackbar>
		);
	};

	/**
	 * Render method
	 * @returns {JSX.Element} Jsx.
	 */
	public render() {
		const { classes, children, image, message, onClick, showBreadCrumb } = this.props;
		const { error, type, continueInSmallScreen, isPrinting, initialized } = this.state;
		if (!initialized) {
			return null;
		}
		if (!error || continueInSmallScreen === Continue.Yes || isPrinting) {
			return (
				<>
					{type === 'screenWidth' && this.renderAlert()}
					{children}
				</>
			);
		}
		return (
			<div className={classes.root}>
				<PageContainer>
					<Box className={classes.breadCrumb}>
						{showBreadCrumb && <BreadCrumb onClick={onClick} />}
					</Box>
					<Paper className={classes.paper}>
						<img src={image} alt="Page error" className={classes.image} />
						<Typography className={classes.message}>{message}</Typography>
						{type === 'alert' && continueInSmallScreen === Continue.NotSet && (
							<Typography variant="h6" className={classes.continueStep}>
								{Resources.Continue} <Button onClick={this.onReject}>No</Button>
								<Button variant="outlined" onClick={this.onContinue}>
									Yes
								</Button>
							</Typography>
						)}
					</Paper>
				</PageContainer>
			</div>
		);
	}
}

export default generateStyle(Style, 'MDFPageError')(withWidth()(PageError));
