import { Theme, createStyles } from '@material-ui/core/styles';

/**
 *
 * @param {Theme} theme Material Theam.
 * @returns {Theme} Return Material theams.
 */
const Styles = (theme: Theme) =>
	createStyles({
		root: {},
		title: {
			color: 'rgba(0, 0, 0, 0.85)',
			marginBottom: theme.spacing(4),
			textDecoration: 'underline',
			fontSize: '24px',
			letterSpacing: '2.14px',
			lineHeight: '32.68px',
			textAlign: 'center'
		},
		titleNormal: {
			color: 'rgba(0, 0, 0, 0.85)',
			marginBottom: theme.spacing(4),
			fontSize: '24px',
			letterSpacing: '2.14px',
			lineHeight: '32.68px',
			textAlign: 'center'
		},
		successMessage: {
			paddingTop: theme.spacing(4)
		},
		emailSent: {
			height: 140,
			width: 140,
			margin: 'auto',
			marginBottom: theme.spacing(2),
			display: 'block'
		},
		message: {
			display: 'flex',
			alignItems: 'center'
		},
		messageIcon: {
			marginRight: theme.spacing(1),
			color: '#6DD400'
		}
	});

export default Styles;
