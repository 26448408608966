export const CHANGETITLE = 'CHANGETITLE';
export const TOGGLEDRAWER = 'TOGGLEDRAWER';

export interface ChangeTitlePayload {
	title: string;
	meta?: string;
}
export interface ChangeTitleAction {
	type: typeof CHANGETITLE;
	payload: ChangeTitlePayload;
	meta?: any;
}

export interface ToggleDrawerAction {
	type: typeof TOGGLEDRAWER;
	payload: boolean;
	meta?: any;
}
export interface MainState {
	title: string;
	meta: string;
	drawer: boolean;
}

export interface MainActions {
	type: typeof CHANGETITLE | typeof TOGGLEDRAWER;
	payload: ChangeTitlePayload | boolean;
	meta?: any;
}
