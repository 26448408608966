import { AxiosRequestConfig } from 'axios';

export interface RequestOptions extends AxiosRequestConfig {
	/**
	 * Append auth to the request
	 */
	secure?: boolean;
}

export interface URL {
	base_url: string;
	domain?: string;
	verb?: string;
	version?: string;
}
export type URLConfig<ClassKey extends string = string> = Record<ClassKey, URL>;

export const createConfig = <ClassKey extends string>(
	styles: URLConfig<ClassKey>
): URLConfig<ClassKey> => {
	return styles;
};
