import { Theme, createStyles } from '@material-ui/core/styles';

/**
 *
 * @param {Theme} theme Material Theam.
 * @returns {Theme} Return Material theams.
 */
const Styles = (theme: Theme) =>
	createStyles({
		root: {},
		breadCrumb: {
			margin: theme.spacing(1, 0)
		},
		paper: {
			flexGrow: 1,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			flexDirection: 'column',
			marginBottom: theme.spacing(2),
			padding: theme.spacing(2)
		},
		snackbarRoot: { margin: 0, top: 56 + 8 },
		image: {
			width: 140,
			height: 'auto',
			maxHeight: 140,
			objectFit: 'contain',
			marginBottom: theme.spacing(1)
		},
		message: {
			fontSize: theme.spacing(4)
		},
		continueStep: {
			'& button': {
				marginRight: theme.spacing(1)
			}
		}
	});

export default Styles;
