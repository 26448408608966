import * as React from 'react';
import get from 'lodash/get';
import { MDFEvents } from '../../constants/events';
import AjaxRequest from '../../network';
import { AjaxSuccess, AjaxError } from '../../utils/events';

export interface Props {
	children?: React.ReactNode;
	[name: string]: any;
}
/**
 *
 */
class AjaxRequests extends React.PureComponent<Props> {
	componentDidMount = () => {
		window.addEventListener(MDFEvents.ApiRequest.name, this.processRequest, true);
	};

	processRequest = async (event) => {
		const data = get(event, 'detail', {});
		const request = get(data, 'request', {});
		const id = get(data, 'id', '');
		AjaxRequest.request({ ...request })
			.toPromise()
			.then((response) => AjaxSuccess(id, response))
			.catch((error) => AjaxError(id, error));
	};

	/**
	 * Render method
	 * @returns {JSX.Element} Jsx.
	 */
	public render() {
		const { children } = this.props;
		return <>{children}</>;
	}
}

export default AjaxRequests;
