import * as React from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { SnackbarProvider, SnackbarProviderProps } from 'notistack';
import Style from './style';
import NotificationComponent from '../NotificationComponent';
import { generateStyle } from '../../utils/functions';

export interface ContainerProps
	extends WithStyles<typeof Style>,
		Omit<SnackbarProviderProps, 'classes'> {
	[name: string]: any;
}
/**
 *
 */
class Notifications extends React.PureComponent<ContainerProps> {
	/**
	 * Render method
	 * @returns {JSX.Element} Jsx.
	 */
	public render() {
		const { classes, ...rest } = this.props;
		return (
			<SnackbarProvider
				maxSnack={1}
				anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
				autoHideDuration={10000}
				transitionDuration={300}
				TransitionProps={
					{
						direction: 'down'
					} as any
				}
				classes={{
					variantError: classes.error,
					variantSuccess: classes.success,
					variantWarning: classes.warning,
					variantInfo: classes.info
				}}
				{...rest}
			>
				<NotificationComponent />
			</SnackbarProvider>
		);
	}
}

export default generateStyle(Style, 'MDF-SDK-Notifications')(Notifications);
