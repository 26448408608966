import { AjaxError } from 'rxjs/ajax';
import { ApplicationsState, Actions, GET_APPS } from './types';
import { ActionStatus } from '../../constants/api';

const INITIAL_STATE: ApplicationsState = {
	apps: [],
	getApps: ActionStatus.LOADING,
	errorData: {}
};
/**
 * Reducer.
 * @param {any} state -  state.
 * @param {any} action - One of actions
 * @returns {any}  state object.
 */
const AppsReducer = (
	state: ApplicationsState = INITIAL_STATE,
	action: Actions
): ApplicationsState => {
	switch (action.type) {
		case GET_APPS.name: {
			return {
				...state,
				getApps: ActionStatus.LOADING
			};
		}
		case GET_APPS.success: {
			const response = action.payload;
			return {
				...state,
				apps: [...response.data],
				getApps: ActionStatus.SUCCESS
			};
		}
		case GET_APPS.failure: {
			const response = action.payload as AjaxError;
			return {
				...state,
				getApps: ActionStatus.FAILURE,
				errorData: response
			};
		}
		case GET_APPS.clear: {
			return {
				...state,
				...INITIAL_STATE
			};
		}
		default:
			return state;
	}
};

export default AppsReducer;
