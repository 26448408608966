import * as React from 'react';
import { UserProps } from './types';
import { UserContext } from '.';
import { OmitProps } from '../../utils/generics';

export type WithUserProps = UserProps;
/**
 *
 * @param {any} Component - Input Component.
 * @returns {Component} Component.
 */
const withUser = <P extends WithUserProps>(
	Component: React.ComponentType<P>
): React.FC<OmitProps<P, WithUserProps>> => (props: OmitProps<P, WithUserProps>): JSX.Element => {
	return (
		<UserContext.Consumer>
			{(value: WithUserProps): JSX.Element => <Component {...props} {...(value as P)} />}
		</UserContext.Consumer>
	);
};

export default withUser;
