import get from 'lodash/get';
import { OptionsObject } from 'notistack';
import { nanoid } from 'nanoid';
import random from 'lodash/random';
import { APPLICATIONEVENTS, APPLICATIONEVENTSSUCCESS, MDFEvents } from '../constants/events';
import { RequestOptions } from '../network/types';

export const APIException = (error) => {
	const errorEvent = new CustomEvent(APPLICATIONEVENTS.APIEXCEPTION, {
		detail: error
	});
	window.dispatchEvent(errorEvent);
	return {
		type: 'APPLICATIONEVENTS.APIEXCEPTION',
		payload: error
	};
};

export const RefreshToken = () => {
	return new Promise<any>((resolve, reject) => {
		window.addEventListener(
			APPLICATIONEVENTSSUCCESS.REFRESHTOKENSUCCESS,
			function refreshSuccessEvent(evt) {
				window.removeEventListener(
					APPLICATIONEVENTSSUCCESS.REFRESHTOKENSUCCESS,
					refreshSuccessEvent
				);
				const refreshToken = get(evt, 'detail', {});
				// call any handler you want here, if needed
				resolve(refreshToken);
			}
		);
		window.addEventListener(
			APPLICATIONEVENTSSUCCESS.REFRESHTOKENERROR,
			function refreshSuccessEvent(evt) {
				window.removeEventListener(
					APPLICATIONEVENTSSUCCESS.REFRESHTOKENERROR,
					refreshSuccessEvent
				);
				const refreshToken = get(evt, 'detail', {});
				// call any handler you want here, if needed
				reject(refreshToken);
			}
		);
		const refreshTokenEvent = new CustomEvent(APPLICATIONEVENTS.REFRESHTOKEN, {
			detail: {}
		});
		window.dispatchEvent(refreshTokenEvent);
	});
};

export const RefreshTokenSuccess = (details) => {
	const refreshTokenEvent = new CustomEvent(APPLICATIONEVENTSSUCCESS.REFRESHTOKENSUCCESS, {
		detail: details
	});
	window.dispatchEvent(refreshTokenEvent);
};

export const RefreshTokenError = (details) => {
	const refreshTokenEvent = new CustomEvent(APPLICATIONEVENTSSUCCESS.REFRESHTOKENERROR, {
		detail: details
	});
	window.dispatchEvent(refreshTokenEvent);
};

export const Ajax = (options: RequestOptions) => {
	const id = nanoid(random(3, 8, false));
	const success = `${APPLICATIONEVENTS.APIREQUEST}/${id}/success`;
	const error = `${APPLICATIONEVENTS.APIREQUEST}/${id}/error`;
	return new Promise<any>((resolve, reject) => {
		window.addEventListener(success, function refreshSuccessEvent(evt) {
			window.removeEventListener(success, refreshSuccessEvent);
			const data = get(evt, 'detail', {});
			// call any handler you want here, if needed
			resolve(data);
		});
		window.addEventListener(error, function refreshSuccessEvent(evt) {
			window.removeEventListener(error, refreshSuccessEvent);
			const errorData = get(evt, 'detail', {});
			// call any handler you want here, if needed
			reject(errorData);
		});
		const apiRequestEvent = new CustomEvent(MDFEvents.ApiRequest.name, {
			detail: {
				request: { ...options },
				id
			}
		});
		window.dispatchEvent(apiRequestEvent);
	});
};

export const AjaxSuccess = (id, details) => {
	const success = `${APPLICATIONEVENTS.APIREQUEST}/${id}/success`;
	const refreshTokenEvent = new CustomEvent(success, {
		detail: details
	});
	window.dispatchEvent(refreshTokenEvent);
};

export const AjaxError = (id, details) => {
	const error = `${APPLICATIONEVENTS.APIREQUEST}/${id}/error`;
	APIException(details);
	const refreshTokenEvent = new CustomEvent(error, {
		detail: details
	});
	window.dispatchEvent(refreshTokenEvent);
};

export const Notify = (message = '', config: OptionsObject = {}) => {
	const refreshTokenEvent = new CustomEvent(APPLICATIONEVENTS.NOTIFICATION, {
		detail: {
			message,
			config
		}
	});
	window.dispatchEvent(refreshTokenEvent);
};
