import { detect } from 'detect-browser';

/**
 * This plugin defines a global device object, which describes the device's hardware and software.
 * Although the object is in the global scope, it is not available until after the deviceready event.
 */
interface Device {
	/** Get the version of Cordova running on the device. */
	cordova: string;
	/** Indicates that Cordova initialize successfully. */
	available: boolean;
	/**
	 * The device.model returns the name of the device's model or product. The value is set
	 * by the device manufacturer and may be different across versions of the same product.
	 */
	model: string;
	/** Get the device's operating system name. */
	platform: string;
	/** Get the device's Universally Unique Identifier (UUID). */
	uuid: string;
	/** Get the operating system version. */
	version: string;
	/** Get the device's manufacturer. */
	manufacturer: string;
	/** Whether the device is running on a simulator. */
	isVirtual: boolean;
	/** Get the device hardware serial number. */
	serial: string;
}
/**
 * Network
 */
interface Network {
	/**
	 * onLine Flag
	 */
	isOnline: boolean;
}

export interface DeviceProps {
	device: Device;
	browser: ReturnType<typeof detect>;
	network: Network;
}

export const InitialDevice: DeviceProps = {
	device: {
		cordova: '',
		available: false,
		model: '',
		platform: '',
		uuid: '',
		version: '',
		manufacturer: '',
		isVirtual: false,
		serial: ''
	},
	browser: null,
	network: {
		isOnline: navigator.onLine
	}
};
