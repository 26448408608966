import * as React from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { WithStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Home } from '@material-ui/icons';
import Style from './style';
import { generateStyle } from '../../utils/functions';
import Resources from '../../constants/resource';

export interface Props extends WithStyles<typeof Style> {
	[name: string]: any;
	onClick?: (e) => void;
}

/**
 * BreadCrumb
 */
class BreadCrumb extends React.PureComponent<Props> {
	/**
	 * Render method
	 * @returns {JSX.Element} Jsx.
	 */
	render() {
		const { classes, onClick } = this.props;
		return (
			<Box className={classes.breadCrumbs}>
				<Breadcrumbs separator="/" aria-label="breadcrumb">
					<span
						className={classes.breadCrumbItem}
						role="presentation"
						onClick={onClick}
						color="inherit"
					>
						<Home />
						<Typography variant="body1">{Resources.AllAppsTitle}</Typography>
					</span>
				</Breadcrumbs>
			</Box>
		);
	}
}

export default generateStyle(Style, 'MDFBreadCrumb')(BreadCrumb);
