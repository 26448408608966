import { ofType, ActionsObservable } from 'redux-observable';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of, from } from 'rxjs';
import { Ajax } from '../../utils/events';
import { Actions, GET_APPS } from './types';

const {
	REACT_APP_API_URL = '',
	REACT_APP_BASE_URL = '',
	REACT_APP_API_GET_APPS = '',
	REACT_APP_CLIENT_ID = ''
} = process.env;
const GET_APP_API = REACT_APP_API_URL + REACT_APP_BASE_URL + REACT_APP_API_GET_APPS;
/**
 *  Epic
 * @param {any} action$ - Action.
 * @param {any} state$ - State.
 * @returns {ActionsObservable} Epic.
 */
export const GetAppsEpic: any = (action$: ActionsObservable<Actions>) =>
	action$.pipe(
		ofType(GET_APPS.name),
		switchMap((action) => {
			return from(
				Ajax({
					method: 'GET',
					url: `${GET_APP_API}?clientId=${REACT_APP_CLIENT_ID}`,
					secure: true
				})
			).pipe(
				map((response) => GET_APPS.successAction(response, action)),
				catchError((error) => of(GET_APPS.failureAction(error, action)))
			);
		})
	);

export const getAppsEpic = GetAppsEpic;
