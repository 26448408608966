import * as React from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Typography, Box, Button } from '@material-ui/core';
import OtpInput from 'react-otp-input';
import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import { time } from 'console';
import Style from './style';
import { CommonPropsWithRef } from '../../utils/generics';
import { withRef } from '../../hoc/withRef';
import { MDFAjax } from '../../network';
import { LOGINCONFIG, RESET_TIMER } from '../../constants/variables';
import { ActionStatus } from '../../constants/api';
import LoadingButton from '../LoadingButton';
import { NotifyError, getRelativePath, generateStyle } from '../../utils/functions';
import Resources from '../../constants/resource';

export interface Props extends CommonPropsWithRef<HTMLDivElement>, WithStyles<typeof Style> {
	// pass
	userPreference: Record<string, any>;
	username?: string;
	onSuccess?: (e) => void;
	onReset?: (e) => void;
	api: MDFAjax<keyof typeof LOGINCONFIG>;
}
type otpMode = 'SMS' | 'MAIL';
interface State {
	otp: any;
	otpMode: otpMode;
	message: '';
	reset: ActionStatus;
	request: ActionStatus;
	timer: number;
}
/**
 *
 */
export class OTPForm extends React.Component<Props, State> {
	_timer = setInterval(() => {}, 1);

	constructor(props: Props) {
		super(props);
		this.state = {
			otp: '',
			message: '',
			otpMode: 'SMS',
			timer: 0,
			reset: ActionStatus.NONE,
			request: ActionStatus.NONE
		};
	}

	componentDidMount = () => {
		this.initializeTimer();
	};

	componentDidUpdate(prevProps: Props) {
		const prevPref = prevProps.userPreference;
		const { userPreference } = this.props;
		const referenceId = get(userPreference, 'referenceId', '');
		const isPhoneAvailable = get(userPreference, 'isPhoneAvailable', false);
		const isEmailAvailable = get(userPreference, 'isEmailAvailable', false);
		if (prevPref !== userPreference && !isEmpty(referenceId)) {
			if (isPhoneAvailable) {
				this._generateOTP('SMS');
			} else if (isEmailAvailable) {
				this._generateOTP('MAIL');
			}
		}
	}

	componentWillUnmount() {
		clearInterval(this._timer);
	}

	initializeTimer = () => {
		this.setState({ reset: ActionStatus.LOADING, timer: RESET_TIMER }, () => {
			clearInterval(this._timer);
			this._timer = setInterval(() => {
				const { timer } = this.state;
				if (timer <= 0) {
					clearInterval(this._timer);
					this.setState({ timer: 0, reset: ActionStatus.NONE });
				} else {
					this.setState({ timer: timer - 1 });
				}
			}, 1000);
		});
	};

	clearTimer = () => {
		this.setState({ timer: 0, reset: ActionStatus.NONE }, () => {
			clearInterval(this._timer);
		});
	};

	private _loginAsDifferent = () => {
		const { onReset } = this.props;
		if (onReset) {
			onReset({});
		}
		return false;
	};

	private _generateOTP = (mode: otpMode) => {
		const { api, userPreference } = this.props;
		const { timer } = this.state;
		if (timer > 0) {
			return false;
		}
		api.post(
			'authenticate',
			{ authType: 'PASSWORDBASED' },
			{
				headers: {
					...userPreference
				}
			}
		)
			.toPromise()
			.then(() => this.initializeTimer())
			.catch((d) => {
				NotifyError(d);
				this.clearTimer();
			});
		return false;
	};

	private _handleChange = (otp) => {
		this.setState({ otp });
	};

	private _handleTypeChange = (event) => {
		const { value } = event.target;
		this._generateOTP(value);
		this.setState({ otpMode: value });
	};

	private _closeError = () => {
		this.setState({ reset: ActionStatus.NONE, request: ActionStatus.NONE, timer: 0 });
	};

	private _authenticateOTP = () => {
		const { onSuccess, api, userPreference } = this.props;
		const { otp } = this.state;
		const referenceId = get(userPreference, 'username', '');
		this.setState({ request: ActionStatus.LOADING }, () => {
			api.post(
				'otp',
				{},
				{
					headers: {
						username: referenceId,
						otp
					}
				}
			)
				.toPromise()
				.then((d) => {
					if (onSuccess) {
						onSuccess({ ...d.data, userReference: userPreference });
					}
				})
				.catch((d) => {
					NotifyError(d, 'otp');
					this.setState({ request: ActionStatus.FAILURE });
				});
		});
	};

	/**
	 * Render method
	 * @returns {JSX.Element} Jsx.
	 */
	public render() {
		const { classes, className, innerRef, username = '' } = this.props;
		const { otp, otpMode, reset, timer, message, request } = this.state;
		const { userPreference } = this.props;
		const referenceId = get(userPreference, 'username', '');
		return (
			<div className={clsx(classes.root, className)} ref={innerRef}>
				{username === '' && (
					<Typography variant="h6" align="center" paragraph className={classes.title}>
						Enter OTP
					</Typography>
				)}
				<Box className={classes.formBox}>
					{/*
				{request === ActionStatus.FAILURE && (
					<Snackbar
						open={request === ActionStatus.FAILURE}
						onClose={this._closeError}
						autoHideDuration={5000}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right'
						}}
					>
						<Alert
							variant="standard"
							elevation={3}
							severity="error"
							onClose={this._closeError}
						>
							{message}
						</Alert>
					</Snackbar>
				)}
				<FormControl component="fieldset" className={classes.otpTypeFrom}>
					<RadioGroup
						aria-label="gender"
						name="gender1"
						value={otpMode}
						onChange={this._handleTypeChange}
					>
						<FormControlLabel
							value="SMS"
							disabled={!isPhoneAvailable}
							control={<Radio color="primary" />}
							label="Request OTP on Mobile Number"
						/>
						<FormControlLabel
							value="MAIL"
							disabled={!isEmailAvailable}
							control={<Radio color="primary" />}
							label="Request OTP on Email"
						/>
					</RadioGroup>
				</FormControl>
				<br />
				<br />
				<Divider />
					<br /> */}
					<form method="POST" action="/" onSubmit={(e) => e.preventDefault()}>
						<img
							src={getRelativePath('images/otp-lock.webp')}
							className={classes.otpImage}
							alt="OTP"
						/>
						<Typography variant="body2" align="center">
							{Resources.OTPSentMessage}
						</Typography>
						<Box className={classes.otpBox}>
							<OtpInput
								onChange={this._handleChange}
								numInputs={4}
								shouldAutoFocus
								value={otp}
								inputStyle={classes.otpInput}
								separator={<span className={classes.separator}>-</span>}
							/>
						</Box>
						<Box className={classes.actions}>
							<span
								role="presentation"
								onClick={(e) => this._generateOTP('SMS')}
								className={classes.link}
							>
								Resend OTP
								{timer > 0 && <>&nbsp;in {timer}s</>}
							</span>
						</Box>
						<Box mb={2}>
							<LoadingButton loading={request === ActionStatus.LOADING}>
								<Button
									type="submit"
									variant="contained"
									fullWidth
									color="primary"
									onClick={this._authenticateOTP}
									disabled={otp.length <= 3 || request === ActionStatus.LOADING}
								>
									{Resources.Login}
								</Button>
							</LoadingButton>
						</Box>
						<Box className={classes.actions}>
							<span
								role="presentation"
								onClick={this._loginAsDifferent}
								className={classes.link}
							>
								Login as a different user
							</span>
						</Box>
					</form>
				</Box>
			</div>
		);
	}
}

export default generateStyle(Style, 'OTPForm')(withRef(OTPForm));
