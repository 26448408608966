import * as React from 'react';
import {
	CssBaseline,
	createGenerateClassName,
	StylesProvider,
	Theme,
	ThemeProvider
} from '@material-ui/core';

import { nanoid } from 'nanoid';
import MeraDmartTheme, { MeraDmartCustomTheme } from '../../constants/theme';

const { REACT_APP_CLIENT_NAME = '' } = process.env;
/**
 * Generate App name
 * @param {string} name = App name
 * @returns {void}
 */
const generateClassName = (name = '') =>
	createGenerateClassName({
		productionPrefix: nanoid(2),
		seed: `${nanoid(4)}_${name}`
	});

export interface IMeraDmartBaseProps {
	children?: React.ReactNode;
	theme: Theme;
	themeType: 'base' | 'custom';
	appName: string;
}
/**
 * Dmart Theme Component
 */
class MeraDmartBase extends React.PureComponent<IMeraDmartBaseProps> {
	static defaultProps = {
		theme: MeraDmartTheme,
		themeType: 'base',
		appName: REACT_APP_CLIENT_NAME
	};

	/**
	 * Render method
	 * @returns {JSX.Element} Jsx.
	 */
	componentDidMount() {
		if (!document.getElementById('TourFont')) {
			const link = document.createElement('link');
			link.id = 'TourFont';
			link.rel = 'stylesheet';
			link.href = 'https://fonts.googleapis.com/css2?family=Comic+Neue&display=swap';
			document.head.appendChild(link);
		}
	}

	/**
	 * Render method
	 * @returns {JSX.Element} Jsx.
	 */
	public render() {
		const { themeType, children, appName, theme } = this.props;
		const StyleID = generateClassName(appName);
		return (
			<StylesProvider generateClassName={StyleID}>
				<ThemeProvider theme={themeType === 'base' ? theme : MeraDmartCustomTheme}>
					<CssBaseline />
					{children}
				</ThemeProvider>
			</StylesProvider>
		);
	}
}

export default MeraDmartBase;
