import { LocalforageInit } from './config';
import { Encryption } from './encryption';
import { OfflineDatabase } from './offlinedb';
import { SECRETKEY, APPNAME } from '../constants/config';

const { REACT_APP_SECRET_KEY = SECRETKEY, REACT_APP_NAME = APPNAME } = process.env;

export class ApplicationDatabase {
	private _applicationName: string;

	private _privateKey: string;

	private _offlineDB: OfflineDatabase;

	private _encryption: Encryption;

	constructor(application_name: string) {
		LocalforageInit();
		this._applicationName = application_name;
		this._encryption = new Encryption(REACT_APP_SECRET_KEY + application_name);
		this._privateKey = this._encryption.hashData(REACT_APP_SECRET_KEY);
		const dbName = this._encryption.hashData(this._applicationName);
		this._offlineDB = new OfflineDatabase(dbName, dbName, this._privateKey);
	}

	private async _clearDB(key: string) {
		try {
			await this._offlineDB.db.ready();
			const db = new OfflineDatabase(key, key, this._privateKey);
			await db.clearDB();
			return true;
		} catch (error) {
			console.log('clearAllDbs', error);
			return true;
		}
	}

	public async clearAllDbs() {
		try {
			await this._offlineDB.db.ready();
			const data = await this._offlineDB.getJson();
			const keys = Object.keys(data);
			const promise: Array<Promise<any>> = [];
			for (let index = 0; index < keys.length; index += 1) {
				const k = keys[index];
				if (!k.endsWith('_value')) {
					promise.push(this._clearDB(k));
				}
			}

			await Promise.all(promise);
			return true;
		} catch (error) {
			console.log('clearAllDbs', error);
			return true;
		}
	}

	private async _deleteDB(key: string) {
		try {
			await this._offlineDB.db.ready();

			const db = new OfflineDatabase(key, key, this._privateKey);
			await db.deleteDB();
			await this._offlineDB.removeKey(key);
			return true;
		} catch (error) {
			console.log('clearAllDbs', error);
			return true;
		}
	}

	public async deleteAllDbs() {
		try {
			await this._offlineDB.db.ready();
			const data = await this._offlineDB.getJson();
			const keys = Object.keys(data);
			const promise: Array<Promise<any>> = [];
			keys.forEach((k) => {
				if (!k.endsWith('_value')) {
					promise.push(this._deleteDB(k));
				}
			});
			await Promise.all(promise);
			await this._offlineDB.db.clear();
			return true;
		} catch (error) {
			console.log('clearAllDbs', error);
			return true;
		}
	}

	public createDB<T>(dbname: string) {
		const name = this._encryption.hashData(dbname);
		const value = this._encryption.encrypt(true, name);
		this._offlineDB.storeKey(name, value).catch((error) => {
			console.log('createDB Error', error);
		});
		return new OfflineDatabase<T>(dbname, name, this._privateKey);
	}
}

export const MDFDB = new ApplicationDatabase(REACT_APP_NAME);
