import { CHANGETITLE, TOGGLEDRAWER, MainState, MainActions } from './types';

const INITIAL_STATE: MainState = {
	title: '',
	meta: '',
	drawer: false
};

/**
 * Reducer.
 * @param {any} state -  state.
 * @param {any} action - One of actions
 * @returns {any}  state object.
 */
const MainReducer = (state: MainState = INITIAL_STATE, action: MainActions): MainState => {
	switch (action.type) {
		case CHANGETITLE: {
			return {
				...state,
				...(action.payload as any)
			};
		}
		case TOGGLEDRAWER: {
			return {
				...state,
				drawer: action.payload as boolean
			};
		}

		default:
			return state;
	}
};

export default MainReducer;
