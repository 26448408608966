import compact from 'lodash/compact';
import { withStyles } from '@material-ui/core';
import { nanoid } from 'nanoid';
import get from 'lodash/get';
import jwt from 'jwt-decode';
import { REGEX_EMAIL, REGEX_NUMBER, REGEX_EMPID } from '../constants/variables';
import { ExceptionMessages } from '../constants/messages';
import { Notify } from './events';
import Resources from '../constants/resource';

export const rem = (value: number, base = 16) => {
	return `${(value / base).toFixed(2)}rem`;
};

const Global = nanoid(3);
export const isPhoneNumber = (value: string) => {
	if (!value) {
		return false;
	}
	return REGEX_NUMBER.test(value.toLowerCase());
};

export const isEmpID = (value: string) => {
	if (!value) {
		return false;
	}
	return REGEX_EMPID.test(value.toLowerCase());
};

export const isEmail = (value: string) => {
	if (!value) {
		return false;
	}
	return REGEX_EMAIL.test(value.toLowerCase());
};

export const generateStyle = (style, name = '') => {
	const id = nanoid(6);
	const { REACT_APP_ID = '' } = process.env;
	const names = compact([Global, id, REACT_APP_ID, name]).join('-');
	return withStyles(style, { name: `${names}` });
};

export const NotifyError = (error, mode: keyof typeof ExceptionMessages | '' = 'login') => {
	const responseStatus = get(error, 'response.status', 0);
	const errorMessage = get(error, 'response.data.message', '');
	const errorStatus = get(error, 'status', 0);
	const status = responseStatus === 0 ? errorStatus : responseStatus;
	const ErrorMode = get(ExceptionMessages, mode, {});
	let message = errorMessage;
	if (status >= 400 && message === '') {
		message = get(ErrorMode, status, message);
	} else if (message === '') {
		message = 'Network error. Please contact IT support!';
	}
	Notify(message, { variant: 'error' });
};

export const readAccessToken = (AccessToken: string) => {
	const token = AccessToken.replace('Bearer ', '');
	const decodedToken = jwt(token);
	return decodedToken;
};

/**
 * @param path starting with the direct folder
 */
export const getRelativePath = (path = '') => {
	const { NODE_ENV } = process.env;
	if (NODE_ENV !== 'development') {
		const { REACT_APP_PUBLIC_URL = '/' } = process.env;
		return `${REACT_APP_PUBLIC_URL}static/${path}`;
	}
	return `/static/${path}`;
};
