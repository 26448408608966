import { createConfig } from '../network/types';

export const DEFAULTUSERINFO = {
	user: [
		'firstName',
		'email',
		'lastName',
		'mobile',
		'siteId',
		'twoFactorAuthEnabled',
		'whNumber',
		{ manager: ['firstName', 'email', 'lastName', 'mobile', 'siteId', 'twoFactorAuthEnabled'] }
	],
	roles: ['name'],
	userApps: [
		'applicationCode',
		'applicationName',
		'applicationIcon',
		'applicationIndex',
		'metaDescription',
		'applicationTitle',
		'applicationUrl',
		'isActive'
	]
};

export const REGEX_EMAIL = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
export const REGEX_NUMBER = /^(\+91[-\s]?)?[0]?(91)?[7896]\d{9}$/;
export const REGEX_EMPID = /^[0-9]{0,10}$/;
export const RESET_TIMER = 60;
export const LOGINCONFIG = createConfig({
	authenticate: {
		base_url: '/iam',
		verb: '/authenticate',
		version: '/v1'
	},
	precheck: {
		base_url: '/iam/login',
		verb: '/precheck',
		version: '/v1'
	},
	anonymus: {
		base_url: '/iam/pwd',
		verb: '/update/a',
		version: '/v1'
	},
	otp: {
		base_url: '/iam',
		verb: '/otp',
		version: '/v1'
	},
	validate: {
		base_url: '/iam/user',
		verb: '/token',
		version: '/v1'
	},
	refresh: {
		base_url: '/iam/token',
		verb: '/refresh',
		version: '/v1'
	},
	forget: {
		base_url: '/iam/pwd',
		verb: '/forgot',
		version: '/v1'
	},
	reset: {
		base_url: '/iam/pwd',
		verb: '/set',
		version: '/v1'
	},
	update: {
		base_url: '/iam/pwd',
		verb: '/update',
		version: '/v1'
	},
	logout: {
		base_url: '/iam',
		verb: '/logout',
		version: '/v1'
	}
});
