import { AjaxError } from 'rxjs/ajax';
import CreateAction from '../../utils/actions';
import { ActionStatus } from '../../constants/api';

export const GET_APPS = CreateAction('GET_APPS');

export interface Token {
	token: string;
	userName: string;
	[name: string]: any;
}
export interface ApplicationsState {
	apps: Array<any>;
	getApps: ActionStatus;
	errorData: Partial<AjaxError>;
}

export interface Actions {
	type: string;
	payload: any;
	meta?: any;
}
