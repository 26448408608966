import { createMuiTheme } from '@material-ui/core';

import { Theme } from '@material-ui/core/styles/createMuiTheme';

export const MeraDmartTheme: Theme = createMuiTheme({
	palette: {
		primary: { main: '#046D39' },
		secondary: { main: '#165161' }
	},
	meraDmart: {
		common: {
			iconSize: 30
		}
	},
	overrides: {
		MuiInputLabel: {
			root: {
				color: 'rgba(0, 0, 0, 0.87)'
			}
		},
		MuiOutlinedInput: {
			notchedOutline: {
				borderColor: 'rgba(0, 0, 0, 0.87)'
			},
			input: {
				'&::placeholder': {
					color: 'rgb(0, 0, 0) !important',
					opacity: 0.6
				},
				'&::-webkit-input-placeholder': {
					color: 'rgb(0, 0, 0) !important',
					opacity: 0.6
				}
			}
		},
		MuiInput: {
			input: {
				'&::placeholder': {
					color: 'rgb(0, 0, 0) !important',
					opacity: 0.6
				},
				'&::-webkit-input-placeholder': {
					color: 'rgb(0, 0, 0) !important',
					opacity: 0.6
				}
			}
		},
		MuiTableSortLabel: {
			root: {
				'&:hover': {
					color: 'rgba(0, 0, 0, 0.87)'
				},
				'&:hover $icon': {
					opacity: 1
				}
			}
		},
		MuiSnackbar: {
			root: {
				marginTop: 56
			}
		}
	},
	props: {
		MuiTextField: {
			fullWidth: true,
			variant: 'outlined',
			margin: 'normal',
			InputLabelProps: {
				shrink: true
			}
		},
		MuiCheckbox: {
			color: 'primary'
		},
		MuiRadio: {
			color: 'primary'
		}
	}
});

export default MeraDmartTheme;
export const MeraDmartCustomTheme: Theme = createMuiTheme({
	palette: {
		primary: { main: '#1E88E5' },
		secondary: { main: '#E57B1E' }
	},
	meraDmart: {
		common: {
			iconSize: 24
		}
	}
});
