/* eslint-disable camelcase */
import * as React from 'react';
import get from 'lodash/get';
import { detect } from 'detect-browser';
import { DeviceProps, InitialDevice } from './types';

interface DeviceProviderProps {
	children?: React.ReactNode;
	[name: string]: any;
}
type DeviceProviderState = DeviceProps;
export const DeviceContext = React.createContext<DeviceProps>({ ...InitialDevice });

/**
 * User Provider
 */
class DeviceProvider extends React.Component<DeviceProviderProps, DeviceProviderState> {
	/**
	 *
	 * @param {object} props Properties
	 */
	constructor(props) {
		super(props);
		this.state = {
			...InitialDevice
		};
	}

	/**
	 * Component did Mount.
	 * @returns {void}
	 */
	componentDidMount(): void {
		const browser = detect();
		if (browser) {
			this.setState({ browser });
		}
		const network = get(navigator, 'connection.type', '');
		if (network && network !== 'NONE') {
			this.setState({ network: { isOnline: true } });
		}
		document.addEventListener(
			'online',
			() => {
				this.setState({ network: { isOnline: true } });
			},
			false
		);
		document.addEventListener(
			'offline',
			() => {
				this.setState({ network: { isOnline: false } });
			},
			false
		);

		window.addEventListener('online', () => {
			this.setState({ network: { isOnline: true } });
		});
		window.addEventListener('offline', () => {
			this.setState({ network: { isOnline: false } });
		});
	}

	/**
	 * Render method
	 * @returns {JSX.Element} Jsx.
	 */
	render(): JSX.Element {
		const { children } = this.props;
		const { network, device, browser } = this.state;
		return (
			<DeviceContext.Provider value={{ network, device, browser }}>
				{children}
			</DeviceContext.Provider>
		);
	}
}
export default DeviceProvider;
