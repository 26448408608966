import { Theme, createStyles } from '@material-ui/core/styles';

/**
 *
 * @param {Theme} theme Material Theam.
 * @returns {Theme} Return Material theams.
 */
const Styles = (theme: Theme) =>
	createStyles({
		root: {},
		title: {
			color: 'rgba(0, 0, 0, 0.85)',
			marginBottom: theme.spacing(4),
			textDecoration: 'underline',
			fontSize: '24px',
			letterSpacing: '2.14px',
			lineHeight: '32.68px',
			textAlign: 'center'
		}
	});

export default Styles;
