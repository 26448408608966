import { Theme, createStyles } from '@material-ui/core/styles';

/**
 *
 * @param {Theme} theme Material Theam.
 * @returns {Theme} Return Material theams.
 */
const Styles = (theme: Theme) =>
	createStyles({
		root: {},
		snackBar: {
			position: 'absolute',
			top: 0,
			right: 24
		},
		snackBarContainer: {
			marginTop: 0
		},
		success: {
			color: 'rgb(30, 70, 32) !important',
			backgroundColor: 'rgb(237, 247, 237) !important',
			'&  svg': {
				color: 'rgb(30, 70, 32) !important'
			},
			fontWeight: 'bold'
		},
		warning: {
			color: 'rgb(102, 60, 0) !important',
			backgroundColor: 'rgb(255, 244, 229) !important',
			'&  svg': {
				color: 'rgb(102, 60, 0)'
			},
			fontWeight: 'bold'
		},
		error: {
			color: 'rgb(97, 26, 21) !important',
			backgroundColor: 'rgb(253, 236, 234) !important',
			'&  svg': {
				color: '#f44336'
			},
			fontWeight: 'bold'
		},
		info: {
			color: 'rgb(13, 60, 97) !important',
			backgroundColor: 'rgb(232, 244, 253) !important',
			'& svg': {
				color: '#2196f3'
			}
		}
	});

export default Styles;
