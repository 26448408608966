import { Theme, createStyles } from '@material-ui/core/styles';
/**
 *
 * @param {Theme} theme Material Theam.
 * @returns {Theme} Return Material theams.
 */
const Styles = (theme: Theme) =>
	createStyles({
		root: {
			userSelect: 'none'
		},
		appBarRoot: {
			'& > div': {
				maxHeight: '56px !important',
				minHeight: '56px !important',
				height: '56px !important'
			}
		},
		logo: {
			cursor: 'pointer',
			height: 30,
			verticalAlign: 'middle',
			marginRight: theme.spacing(1)
		},
		pinButton: {
			padding: 0
		},
		pin: {
			borderRadius: 0,
			height: 35,
			width: 35,
			verticalAlign: 'middle'
		},
		avatarImg: {
			'object-fit': 'contain'
		},
		appBar: {
			color: theme.palette.primary.main,
			background: '#fff '
		},
		icons: {
			color: theme.palette.primary.main,
			fontSize: '24px !important'
		},
		variantPrimary: {
			color: theme.palette.primary.main
		},
		variantOutlined: {
			color: 'white',
			fontSize: 24
		},
		variantButtonPrimary: {
			color: theme.palette.primary.main
		},
		variantButtonOutlined: {
			color: 'white',
			padding: 8,
			border: '1px solid white'
		},
		variantMenuButtonPrimary: {
			color: theme.palette.primary.main
		},
		variantMenuButtonOutlined: {
			color: 'white'
		},
		iconButton: {
			color: theme.palette.primary.main,
			fontSize: theme.meraDmart.common.iconSize
		},
		backButton: {},
		menuButton: {
			marginRight: theme.spacing(2)
		},
		title: {
			color: 'black',
			flexGrow: 2,
			left: '50%',
			top: '50%',
			position: 'absolute',
			transform: 'translate(-50%,-50%)',
			textTransform: 'uppercase',
			fontSize: theme.spacing(24 / 8),
			lineHeight: 1
		},
		profileButton: {
			color: 'black',
			textTransform: 'lowercase'
		},
		menuRight: {
			marginLeft: 'auto',
			display: 'flex',
			alignItems: 'center'
		},
		empty: {
			flex: 2
		},
		drawerList: {
			width: 240
		},
		drawerUL: {
			paddingTop: 0
		},
		search: {
			paddingLeft: 12
		},
		drawerPaper: {
			height: 'calc(100vh - 56px)',
			top: 56
		},
		drawerLink: {
			color: '#1976d2'
		},
		drawerClose: {
			marginLeft: 'auto'
		},
		drawerSecondaryText: {
			fontSize: 10
		},
		email: {
			[theme.breakpoints.down('xs')]: {
				display: 'none'
			}
		}
	});

export default Styles;
