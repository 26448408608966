import * as React from 'react';
import { WithStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { Typography, Box, Button, InputAdornment, IconButton, Snackbar } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Alert } from '@material-ui/lab';
import truncate from 'lodash/trim';
import Style from './style';
import { AuthEvents } from '../../events';
import { MDFLOGOUT } from '../../constants/events';
import LoadingButton from '../LoadingButton';
import { LOGINCONFIG } from '../../constants/variables';
import { MDFAjax } from '../../network';
import { ActionStatus } from '../../constants/api';
import Resources from '../../constants/resource';
import { generateStyle } from '../../utils/functions';

interface Params {
	token: string;
}
export interface Props extends WithStyles<typeof Style>, RouteComponentProps<Params> {
	// pass
	api: MDFAjax<keyof typeof LOGINCONFIG>;
	set?: boolean;
}
interface State {
	status: ActionStatus;
	validationStatus: ActionStatus;
	message: React.ReactNode;
	passwordVisible: boolean;
	confirmPasswordVisible: boolean;
	formData: {
		userName: string;
		password: string;
		confirmPassword: string;
	};
}
/**
 *
 */
class ResetPasswordForm extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
			const { formData } = this.state;
			const password = get(formData, 'password', '');
			if (value !== password) {
				return false;
			}
			return true;
		});
		this.state = {
			status: ActionStatus.NONE,
			validationStatus: ActionStatus.NONE,
			passwordVisible: false,
			confirmPasswordVisible: false,
			message: '',
			formData: {
				userName: '',
				password: '',
				confirmPassword: ''
			}
		};
	}

	componentDidMount() {
		AuthEvents.emit(MDFLOGOUT);
		this.validateToken();
	}

	validateToken = () => {
		const { api, match } = this.props;

		this.setState({ validationStatus: ActionStatus.LOADING }, () => {
			api.get('validate', {
				params: {
					profileToken: match.params.token
				}
			})
				.toPromise()
				.then((data) => {
					const userName = get(data, 'data.email', '');
					this.setState({
						validationStatus: ActionStatus.SUCCESS,
						formData: { userName, password: '', confirmPassword: '' }
					});
				})
				.catch((error) => {
					const message: any =
						'Reset token is invalid, Please provide valid reset token.';
					this.setState({ validationStatus: ActionStatus.FAILURE, message });
				});
		});
	};

	handleChange = (event) => {
		const { value } = event.target;
		const { name } = event.target;
		const { formData } = this.state;
		formData[name] = truncate(value);
		this.setState({ formData, status: ActionStatus.NONE });
	};

	handleSubmit = (e) => {
		this.setState({ status: ActionStatus.LOADING }, () => {
			const { match, api } = this.props;
			const { formData } = this.state;
			const query = {
				password: formData.password,
				profileUpdateToken: match.params.token
			};
			api.post('reset', {
				...query
			})
				.toPromise()
				.then((data) => {
					const setPassword = get(data, 'data.response.message', '');
					if (!isEmpty(setPassword)) {
						this.setState({
							status: ActionStatus.SUCCESS,
							formData: { userName: '', password: '', confirmPassword: '' }
						});
					}
				})
				.catch((error) => {
					const message: any = 'Request cannot be completed, Please try again.';
					this.setState({ status: ActionStatus.FAILURE, message });
				});
		});
	};

	private _closeError = () => {
		const { formData } = this.state;
		this.setState({
			status: ActionStatus.NONE,
			validationStatus: !isEmpty(formData.userName) ? ActionStatus.SUCCESS : ActionStatus.NONE
		});
	};

	private _closeSnackbar = () => {
		this.setState({ status: ActionStatus.NONE });
	};

	public renderForm = () => {
		const {
			status,
			formData,
			message,
			validationStatus,
			passwordVisible,
			confirmPasswordVisible
		} = this.state;
		if (status === ActionStatus.SUCCESS) {
			return (
				<Box>
					<Alert severity="success" elevation={2}>
						{Resources.PasswordResetSuccessful}
						<br /> <Link to="/">{Resources.LoginLinkTitle}</Link>
					</Alert>
				</Box>
			);
		}
		return (
			<>
				{status === ActionStatus.FAILURE && (
					<Snackbar
						open={status === ActionStatus.FAILURE}
						onClose={this._closeSnackbar}
						autoHideDuration={5000}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right'
						}}
					>
						<Alert
							variant="standard"
							elevation={3}
							severity="error"
							onClose={this._closeSnackbar}
						>
							<strong>{message}</strong>
						</Alert>
					</Snackbar>
				)}
				{validationStatus === ActionStatus.FAILURE && (
					<Snackbar
						open={validationStatus === ActionStatus.FAILURE}
						autoHideDuration={5000}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right'
						}}
					>
						<Alert variant="standard" elevation={3} severity="error">
							<strong>{message}</strong>
						</Alert>
					</Snackbar>
				)}
				<ValidatorForm onSubmit={this.handleSubmit}>
					<TextValidator
						label="Password *"
						placeholder={Resources.PasswordPlaceholder}
						onChange={this.handleChange}
						name="password"
						disabled={validationStatus !== ActionStatus.SUCCESS}
						fullWidth
						type={passwordVisible ? 'text' : 'password'}
						variant="outlined"
						helperText={Resources.PasswordPlaceholder}
						margin="normal"
						InputLabelProps={{
							shrink: true,
							color: 'primary'
						}}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										size="small"
										disabled={validationStatus !== ActionStatus.SUCCESS}
										onClick={() =>
											this.setState({ passwordVisible: !passwordVisible })
										}
									>
										{passwordVisible ? (
											<VisibilityOff
												color={
													validationStatus !== ActionStatus.SUCCESS
														? 'disabled'
														: 'primary'
												}
											/>
										) : (
											<Visibility
												color={
													validationStatus !== ActionStatus.SUCCESS
														? 'disabled'
														: 'primary'
												}
											/>
										)}
									</IconButton>
								</InputAdornment>
							)
						}}
						value={formData.password}
						validators={['required', 'minStringLength:6']}
						errorMessages={[
							Resources.ValidPasswordValidation,
							Resources.PasswordLengthValidation
						]}
					/>
					<TextValidator
						label="Confirm password *"
						placeholder={Resources.EnterNewPassword}
						onChange={this.handleChange}
						name="confirmPassword"
						fullWidth
						disabled={validationStatus !== ActionStatus.SUCCESS}
						type={confirmPasswordVisible ? 'text' : 'password'}
						variant="outlined"
						helperText={Resources.RepeatNewPassword}
						margin="normal"
						InputLabelProps={{
							shrink: true
						}}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										size="small"
										disabled={validationStatus !== ActionStatus.SUCCESS}
										onClick={() =>
											this.setState({
												confirmPasswordVisible: !confirmPasswordVisible
											})
										}
									>
										{confirmPasswordVisible ? (
											<VisibilityOff
												color={
													validationStatus !== ActionStatus.SUCCESS
														? 'disabled'
														: 'primary'
												}
											/>
										) : (
											<Visibility
												color={
													validationStatus !== ActionStatus.SUCCESS
														? 'disabled'
														: 'primary'
												}
											/>
										)}
									</IconButton>
								</InputAdornment>
							)
						}}
						value={formData.confirmPassword}
						validators={['required', 'isPasswordMatch']}
						errorMessages={[
							Resources.InvalidConfirmPassword,
							Resources.PasswordNoMatch
						]}
					/>
					<Box mt={1}>
						<LoadingButton
							loading={
								status === ActionStatus.LOADING ||
								validationStatus === ActionStatus.LOADING
							}
						>
							<Button
								type="submit"
								disabled={
									status === ActionStatus.LOADING ||
									validationStatus !== ActionStatus.SUCCESS ||
									formData.password !== formData.confirmPassword ||
									formData.password === ''
								}
								variant="contained"
								fullWidth
								color="primary"
							>
								{Resources.ResetPassword}
							</Button>
						</LoadingButton>
					</Box>
				</ValidatorForm>
			</>
		);
	};

	/**
	 * Render method
	 * @returns {JSX.Element} Jsx.
	 */
	public render() {
		const { classes, set } = this.props;
		const { status } = this.state;
		return (
			<div className={clsx(classes.root)}>
				<Typography className={classes.title} variant="h6">
					{status === ActionStatus.SUCCESS && 'Password Updated Successfully!'}
					{status !== ActionStatus.SUCCESS && (
						<>{set ? 'Set new password' : 'Reset password'}</>
					)}
				</Typography>
				{this.renderForm()}
			</div>
		);
	}
}

export default generateStyle(Style, 'ResetPasswordForm')(withRouter(ResetPasswordForm));
