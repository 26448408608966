import * as React from 'react';
import { WithStyles, Divider, Link, Button, Badge } from '@material-ui/core';
import cslx from 'clsx';
import { connect } from 'react-redux';

import get from 'lodash/get';
import capitalize from 'lodash/capitalize';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import SearchOutlined from '@material-ui/icons/SearchOutlined';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Close from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import Settings from '@material-ui/icons/Settings';
import Apps from '@material-ui/icons/Apps';
import RecordVoiceOver from '@material-ui/icons/RecordVoiceOver';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/SwipeableDrawer';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import isEmpty from 'lodash/isEmpty';
import truncate from 'lodash/truncate';
import { Notifications, PowerSettingsNew, ArrowDropDown } from '@material-ui/icons';
import withUser, { WithUserProps } from '../Login/consumer';
import { MDFLOGOUT } from '../../constants/events';
import { AuthEvents } from '../../events';
import Style from './style';
import { generateStyle } from '../../utils/functions';
import { ApplicationsState, GET_APPS } from '../../redux/Home/types';
import { RootState } from '../../reducers';

interface HeaderMenuState {
	menuAnchor: any;
	menuOpen: boolean;
	drawerOpen: boolean;
}
export interface HeaderMenuProps extends WithStyles<typeof Style>, WithUserProps {
	applications: ApplicationsState;
	clearApplications: typeof GET_APPS.clearAction;
	onlyHeader?: boolean;
	/**
	 * Menu Title
	 */
	menuTitle?: React.ReactNode;
	/**
	 * Menu right
	 */
	menuRight?: React.ReactNode;
	/**
	 * Enable Back Button
	 */
	showBack?: boolean;
	/**
	 * Enable Search Button
	 */
	showSearch?: boolean;
	/**
	 * Enable Notification Button
	 */
	showNotification?: boolean;
	notificationCount?: number;
	onNotificationClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
	/**
	 * Enable Drawer Button
	 */
	showMenu?: boolean;
	/**
	 * Enable Drawer Button
	 */
	showDrawerClose?: boolean;
	/**
	 * Enable Drawer Button
	 */
	openMenu?: boolean;
	/**
	 * Enable Profile Button
	 */
	showProfile?: boolean;
	/**
	 * Enable Logo Button
	 */
	showLogo?: boolean;
	/**
	 * Enable Search Button
	 */
	transparentBackground?: boolean;
	/**
	 * App Bar elevation
	 */
	elevation?: number;
	/**
	 * Logout Menu Event
	 */
	onLogout?: (e: React.MouseEvent<HTMLLIElement>) => void;
	/**
	 * On DrawerMenuClick
	 */
	onMenuClick?: (id: string) => void;
	/**
	 * Logo Ping Click Menu Event
	 */
	onLogoClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
	/**
	 * Profile Menu Event
	 */
	onProfile?: (e: React.MouseEvent<HTMLLIElement>) => void;
	onSettingsClick?: (e: React.MouseEvent<HTMLLIElement>) => void;
	/**
	 * Search Menu Event
	 */
	onSearch?: (e: React.MouseEvent<HTMLButtonElement>) => void;
	/**
	 * Back Menu Event
	 */
	onBack?: (e: React.MouseEvent<HTMLButtonElement>) => void;
	/**
	 * Back Menu Event
	 */
	onDrawerOpen?: () => void;
	/**
	 * Back Menu Event
	 */
	onDrawerClose?: () => void;
	/**
	 * Menu Variant
	 */
	variant?: 'primary' | 'outlined';
	[name: string]: any;
}

/**
 *
 */
class HeaderMenu extends React.Component<HeaderMenuProps, HeaderMenuState> {
	private _ignore: string[] = [
		'classes',
		'showBack',
		'showDrawerClose',
		'onBack',
		'onDrawerOpen',
		'onMenuClick',
		'onDrawerClose',
		'onMenuClick',
		'menuTitle',
		'openMenu',
		'onLogout',
		'onProfile',
		'variant',
		'onSearch',
		'showProfile',
		'showSearch',
		'elevation',
		'innerRef',
		'className'
	];

	static defaultProps: Partial<HeaderMenuProps> = {
		variant: 'primary',
		menuTitle: '',
		onLogoClick: (e: React.MouseEvent<HTMLButtonElement>) => {
			e.preventDefault();
		},
		onLogout: (e: React.MouseEvent<HTMLLIElement>) => {
			e.preventDefault();
		},
		onSearch: (e: React.MouseEvent<HTMLButtonElement>) => {
			e.preventDefault();
		},
		onProfile: (e: React.MouseEvent<HTMLLIElement>) => {
			e.preventDefault();
		},
		onBack: (e: React.MouseEvent<HTMLButtonElement>) => {
			e.preventDefault();
		},
		showBack: false,
		elevation: 2,
		showLogo: true,
		showProfile: true,
		openMenu: false,
		showDrawerClose: false,
		showSearch: true,
		showMenu: false
	};

	/**
	 * Constructor
	 * @param {IMenuProps} props - Properties.
	 * @returns {void} Contructor.
	 */
	constructor(props: HeaderMenuProps) {
		super(props);
		const { openMenu } = this.props;
		this.state = {
			menuAnchor: null,
			menuOpen: false,
			drawerOpen: openMenu || false
		};
	}

	/**
	 * Constructor
	 * @param {IMenuProps} prevProps - Properties.
	 * @returns {void} Contructor.
	 */
	componentDidUpdate(prevProps: HeaderMenuProps) {
		const { openMenu } = this.props;
		if (prevProps.openMenu !== openMenu) {
			if (openMenu) {
				this.handleDrawerOpen();
			} else {
				this.handleDrawerClose();
			}
		}
	}

	/**
	 *
	 * @returns {void} Change state.
	 */
	handleMenuClose = () => {
		this.setState({
			menuAnchor: null,
			menuOpen: false
		});
	};

	/**
	 *
	 * @returns {void} Change state.
	 */
	handleDrawerOpen = () => {
		this.setState(
			{
				drawerOpen: true
			},
			() => {
				const { onDrawerOpen } = this.props;
				if (onDrawerOpen) {
					onDrawerOpen();
				}
			}
		);
	};

	/**
	 *
	 * @returns {void} Change state.
	 */
	handleDrawerClose = () => {
		this.setState(
			{
				drawerOpen: false
			},
			() => {
				const { onDrawerClose } = this.props;
				if (onDrawerClose) {
					onDrawerClose();
				}
			}
		);
	};

	/**
	 * @param {React.MouseEvent<HTMLElement>} e Mouse Event.
	 * @returns {void} Change state.
	 */
	handleMenuOpen = (e: React.MouseEvent<HTMLElement>) => {
		const target = e.currentTarget;
		this.setState((prevState) => ({
			menuAnchor: target,
			menuOpen: !prevState.menuOpen
		}));
	};

	/**
	 * @param {string} id Mouse Event.
	 * @returns {void} Change state.
	 */
	handleDrawerClick = (id: string) => {
		const { onMenuClick } = this.props;
		this.setState({ drawerOpen: false }, () => {
			if (onMenuClick) {
				onMenuClick(id);
			}
		});
	};

	/**
	 * @param {string} e Mouse Event.
	 * @returns {void} Change state.
	 */
	preventDefault = (e) => {
		e.preventDefault();
	};

	/**
	 * Render method
	 * @returns {JSX.Element} Jsx.
	 */
	renderDrawer() {
		const { REACT_APP_CLIENT_NAME = '' } = process.env;
		const { classes, menuTitle, onLogoClick, showDrawerClose } = this.props;
		const { drawerOpen } = this.state;
		return (
			<Drawer
				variant="persistent"
				anchor="left"
				classes={{
					paper: classes.drawerPaper
				}}
				open={drawerOpen}
				onClose={this.handleDrawerClose}
				onOpen={this.handleDrawerOpen}
				className={classes.drawerList}
			>
				<Box className={classes.drawerList}>
					{showDrawerClose && (
						<Box width="100%" height={50} display="flex" alignItems="center">
							{!isEmpty(menuTitle) && (
								<Box ml={2}>
									<img
										className={classes.logo}
										src="/static/images/logo-mera.jpg"
										alt={REACT_APP_CLIENT_NAME}
										onClick={(e) => {
											this.handleDrawerClose();
											if (onLogoClick) {
												onLogoClick(e as any);
											}
										}}
										role="presentation"
									/>
								</Box>
							)}
							<IconButton
								onClick={this.handleDrawerClose}
								className={classes.drawerClose}
							>
								<Close />
							</IconButton>
						</Box>
					)}
					<List className={classes.drawerUL}>
						<Divider />
						<ListItem button onClick={() => this.handleDrawerClick('all_apps')}>
							<ListItemIcon>
								<Apps />
							</ListItemIcon>
							<ListItemText
								classes={{ secondary: classes.drawerSecondaryText }}
								primary={
									<Link
										href="#"
										className={classes.drawerLink}
										onClick={this.preventDefault}
									>
										All Apps
									</Link>
								}
								secondary="Apps to ease your work"
							/>
						</ListItem>
						<Divider />
						<ListItem button onClick={() => this.handleDrawerClick('speak_up')}>
							<ListItemIcon>
								<RecordVoiceOver />
							</ListItemIcon>
							<ListItemText
								classes={{ secondary: classes.drawerSecondaryText }}
								primary={
									<Link
										href="#"
										className={classes.drawerLink}
										onClick={this.preventDefault}
									>
										Speak up
									</Link>
								}
								secondary="Give anonymous feedback or raise a complaint"
							/>
						</ListItem>
						<Divider />
						<ListItem button onClick={() => this.handleDrawerClick('settings')}>
							<ListItemIcon>
								<Settings />
							</ListItemIcon>
							<ListItemText
								classes={{ secondary: classes.drawerSecondaryText }}
								primary={
									<Link
										href="#"
										className={classes.drawerLink}
										onClick={this.preventDefault}
									>
										Settings
									</Link>
								}
								secondary="Change the appearance and profile"
							/>
						</ListItem>
						<Divider />
					</List>
				</Box>
			</Drawer>
		);
	}

	/**
	 * Render method
	 * @returns {JSX.Element} Jsx.
	 */
	renderIcons() {
		const { REACT_APP_CLIENT_NAME = '' } = process.env;
		const { classes, onLogoClick, variant } = this.props;
		if (variant === 'primary') {
			return (
				<img
					className={classes.logo}
					src="/static/images/logo-mera.jpg"
					alt={REACT_APP_CLIENT_NAME}
					onClick={(e) => {
						if (onLogoClick) {
							onLogoClick(e as any);
						}
					}}
					role="presentation"
				/>
			);
		}
		return (
			<img
				className={classes.logo}
				src="/static/images/logo-mera.jpg"
				alt={REACT_APP_CLIENT_NAME}
				onClick={(e) => {
					if (onLogoClick) {
						onLogoClick(e as any);
					}
				}}
				role="presentation"
			/>
		);
	}

	/**
	 * Render method
	 * @returns {JSX.Element} Jsx.
	 */
	render() {
		const {
			classes,
			showBack,
			onBack,
			onlyHeader = false,
			menuTitle,
			onProfile,
			onSearch,
			showProfile,
			showSearch,
			showNotification,
			notificationCount,
			onNotificationClick,
			showLogo,
			elevation,
			showMenu,
			menuRight,
			innerRef,
			variant,
			user,
			clearApplications,
			className
		} = this.props;
		const { menuAnchor, menuOpen } = this.state;
		const props = {};
		const menuvarient = capitalize(variant);
		const email = get(user, 'email', '');
		return (
			<div className={classes.root} ref={innerRef} {...props}>
				<AppBar
					position="static"
					className={cslx(classes.appBar, className)}
					classes={{
						root: classes.appBarRoot
					}}
					elevation={elevation}
				>
					<Toolbar>
						{showBack && (
							<IconButton
								edge="start"
								aria-label="Back"
								aria-controls="menu-appbar"
								aria-haspopup="true"
								className={cslx([classes.backButton])}
								onClick={onBack}
								color="primary"
							>
								<ArrowBack />
							</IconButton>
						)}
						{showMenu && (
							<IconButton
								edge="start"
								aria-label="menu"
								id="headerMenuIcon"
								className={cslx(`${classes[`variantMenuButton${menuvarient}`]}`)}
								onClick={this.handleDrawerOpen}
							>
								<MenuIcon
									className={cslx(
										`${classes[`variantMenuButton${menuvarient}`]}`
									)}
								/>
							</IconButton>
						)}

						{showLogo && <Box>{this.renderIcons()}</Box>}
						{!isEmpty(menuTitle) && (
							<Typography
								component="div"
								align="center"
								variant="body1"
								className={classes.title}
							>
								{menuTitle}
							</Typography>
						)}
						{!onlyHeader && (
							<Box className={classes.menuRight}>
								{menuRight}
								{showSearch && (
									<IconButton
										aria-label="Search"
										aria-controls="menu-appbar"
										aria-haspopup="true"
										color="primary"
										onClick={onSearch}
									>
										<SearchOutlined
											className={cslx(classes.profileButton, classes.icons)}
										/>
									</IconButton>
								)}
								{showNotification && (
									<IconButton
										aria-label="Search"
										aria-controls="menu-appbar"
										aria-haspopup="true"
										color="primary"
										onClick={onNotificationClick}
									>
										<Badge badgeContent={notificationCount} color="error">
											<Notifications
												className={cslx(
													classes.profileButton,
													classes.icons
												)}
											/>
										</Badge>
									</IconButton>
								)}
								{showProfile && (
									<Button
										aria-label="account of current user"
										aria-controls="menu-appbar"
										aria-haspopup="true"
										onClick={this.handleMenuOpen}
										disableRipple
										disableTouchRipple
										color="primary"
										id="headerProfileIcon"
										endIcon={
											<>
												<AccountCircle
													className={cslx(
														classes.profileButton,
														classes.icons
													)}
												/>
												<ArrowDropDown />
											</>
										}
										className={cslx(
											classes.profileButton,
											`${classes[`variantButton${menuvarient}`]}`
										)}
									>
										<span className={classes.email}>
											{truncate(email, { length: 20 })}
										</span>
									</Button>
								)}
							</Box>
						)}
						<Menu
							id="menu-appbar"
							anchorEl={menuAnchor}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'right'
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right'
							}}
							getContentAnchorEl={null}
							open={menuOpen}
							onClose={this.handleMenuClose}
						>
							<MenuItem onClick={onProfile}>
								<ListItemIcon>
									<Settings color="inherit" className={classes.profileButton} />
								</ListItemIcon>
								<ListItemText>Settings</ListItemText>
							</MenuItem>
							<MenuItem
								onClick={() => {
									clearApplications();
									AuthEvents.emit(MDFLOGOUT, true);
								}}
							>
								<ListItemIcon>
									<PowerSettingsNew
										color="inherit"
										className={classes.profileButton}
									/>
								</ListItemIcon>
								<ListItemText>Logout</ListItemText>
							</MenuItem>
						</Menu>
					</Toolbar>
				</AppBar>
				{this.renderDrawer()}
			</div>
		);
	}
}

/**
 * Map state to props
 * @param {any} state Global state Object
 * @returns {Object} State to pros.
 */
const mapStateToProps = (state: RootState) => ({
	applications: state.applications
});

const mapDispatchToProps = {
	clearApplications: GET_APPS.clearAction
};
export default generateStyle(
	Style,
	'MDFHeaderMenu'
)(connect(mapStateToProps, mapDispatchToProps)(withUser(HeaderMenu)));
