import Localforage from 'localforage';
import get from 'lodash.get';
import isEmpty from 'lodash.isempty';

const cordovaSQLiteDriver = get(window, 'cordovaSQLiteDriver', {});

// Initialize LocalForage FOR SQLITE driver as well as INDEXED DB.
export const LocalforageInit = () => {
	let Drivers = [Localforage.INDEXEDDB, Localforage.WEBSQL, Localforage.LOCALSTORAGE];
	if (!isEmpty(cordovaSQLiteDriver)) {
		Drivers = [cordovaSQLiteDriver._driver, ...Drivers];
	}
	Localforage.config({
		driver: Drivers
	});
};

export const localforageInit = LocalforageInit;
