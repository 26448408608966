import { AjaxError } from 'rxjs/ajax';
import CreateAction from '../../utils/actions';
import { ActionState } from '../../constants/api';

export interface GetFiles {
	id: string;
	url: string;
	getFile: ActionState;
	errorData: Partial<AjaxError>;
	[name: string]: any;
}
export interface FileState {
	getFiles: Record<string, GetFiles>;
	getFile: ActionState;
}

export interface GETFILE {
	id: string;
	url: string;
}
export const GET_FILE = CreateAction<GETFILE>('GET_FILE');
export interface FileActions {
	type: string;
	payload?: any;
	meta?: any;
}
