export default class ValidationError extends Error {
	response = {
		status: 412
	};

	request = {};

	constructor(message, request) {
		super(message);
		this.name = 'ValidationError';
		this.request = request;
	}
}
