import { TokenData } from '../../database/tokenstorage';

export interface UserInfoProps {
	[name: string]: any;
}
export interface UserProps {
	user: UserInfoProps;
	token: TokenData;
}
export const UserInitialState: UserInfoProps = {
	authenticated: false
};
export const INITIALUSERDATA: UserProps = {
	user: UserInitialState,
	token: { accessToken: '', refreshToken: '' }
};
