import localforage from 'localforage';
// import sha256 from 'crypto-js/hmac-sha256';
import { Encryption } from './encryption';
import { MDFEvent } from '../events';
import { MDFDBKEYCHANGE, MDFDBKEYREMOVE, MDFDBCLEAR } from '../constants/events';

type requestType = 'get' | 'post';
interface DataResult<T> {
	key: string;
	value: T;
}
type PromiseResponse<T> = { key: string; result: DataResult<T> };

export class OfflineDatabase<DOC extends any = any> {
	public _name: string;

	private _publicKey: string;

	private _privateKey: string;

	public db: LocalForage;

	private _encryption: Encryption;

	constructor(name: string, encryptedName: string, publicKey: string) {
		this._name = name;
		this._publicKey = publicKey;
		this._encryption = new Encryption(publicKey);
		this._privateKey = this._encryption.hashData(this._publicKey);
		this.db = localforage.createInstance({
			name: encryptedName,
			storeName: this._hash(encryptedName)
		});
	}

	private _encryptValue(value: any, key: string) {
		return this._encryption.encrypt(value, key);
	}

	private _decryptValue(value: any, key: string) {
		return this._encryption.decrypt(value, key);
	}

	private async _getKey<T extends DOC, K extends keyof T>(
		key: K
	): Promise<PromiseResponse<T[K]>> {
		const enc = this._encryption.hashData(key as any);
		const doc = await this.db.getItem<DOC>(enc);
		const result = this._decryptValue(doc, this._hash(enc));
		return { key, result } as any;
	}

	private async _decodeKey<T extends DOC, K extends keyof T>(
		key: K
	): Promise<PromiseResponse<T[K]>> {
		const doc = await this.db.getItem<DOC>(key as any);
		const result = this._decryptValue(doc, this._hash(key));
		return { key: result.key, result: result.value } as any;
	}

	private async _deleteKey<T extends DOC, K extends keyof T>(
		key: K
	): Promise<PromiseResponse<T[K]>> {
		const enc = this._encryption.hashData(key as any);
		MDFEvent.emit(MDFDBKEYREMOVE, { db: this._name, key });
		await this.db.removeItem(enc as any);
		return { key, result: true } as any;
	}

	private async _storeKey<T extends DOC, K extends keyof T>(
		key: K,
		value: T[K]
	): Promise<PromiseResponse<T[K]>> {
		const hash = this._encryption.hashData(key as any);
		const encData = this._encryptValue({ key, value }, this._hash(hash));
		const doc = await this.db.setItem<T[K]>(hash, encData as any);
		MDFEvent.emit(MDFDBKEYCHANGE, { db: this._name, key });
		return { key, result: doc } as any;
	}

	public async getKey<T extends DOC, K extends keyof T>(key: K): Promise<T[K]> {
		try {
			await this.db.ready();
			const result = await this._getKey(key as any);
			if (result.result) {
				return result.result.value as any;
			}
			return result.result as any;
		} catch (error) {
			console.log('getKey', error);
			return '' as any;
		}
	}

	public async getKeys<T extends DOC, K extends keyof T>(key: K[]): Promise<Record<K, any>> {
		try {
			await this.db.ready();
			const keys = key;
			const promise: Array<Promise<PromiseResponse<any>>> = [];
			keys.forEach((dataKey) => {
				promise.push(this._getKey(dataKey as any));
			});
			const results = await Promise.all(promise);
			const output = {};
			results.forEach((items) => {
				output[items.key] = items.result;
			});
			return output as any;
		} catch (error) {
			console.log('getKeys', error);
			return '' as any;
		}
	}

	public async storeKey<T extends DOC, K extends keyof T>(key: K, value: T[K]): Promise<T[K]> {
		try {
			await this.db.ready();
			const result = await this._storeKey(key, value);
			return result.result as any;
		} catch (error) {
			console.log('storeKey', error);
			return '' as any;
		}
	}

	public async storeKeys<T extends DOC, K extends keyof T>(
		config: { key: K; value: T[K] }[]
	): Promise<PromiseResponse<any>[]> {
		try {
			await this.db.ready();
			const promise: Array<Promise<PromiseResponse<any>>> = [];
			config.forEach((item) => {
				promise.push(this._storeKey(item.key, item.value));
			});
			const results = await Promise.all(promise);
			return results;
		} catch (error) {
			console.log('storeKeys', error);
			return '' as any;
		}
	}

	public async getJson(): Promise<DOC> {
		try {
			await this.db.ready();
			const keys = await this.db.keys();
			const promise: Array<Promise<PromiseResponse<any>>> = [];
			keys.forEach((item) => {
				promise.push(this._decodeKey(item as any));
			});
			const results = await Promise.all(promise);
			const output = {};
			results.forEach((r) => {
				output[r.key] = r.result;
			});
			return output as any;
		} catch (error) {
			console.log('getJson', error);
			return '' as any;
		}
	}

	public async removeKey<T extends DOC, K extends keyof T>(key: K): Promise<boolean> {
		try {
			await this.db.ready();
			await this._deleteKey(key as any);
			return true;
		} catch (error) {
			console.log('removeKey', error);
			return true;
		}
	}

	public async removeKeys<T extends DOC, K extends keyof T>(key: K): Promise<boolean> {
		try {
			await this.db.ready();
			await this._deleteKey(key as any);
			return true;
		} catch (error) {
			console.log('removeKeys', error);
			return true;
		}
	}

	public async clearDB(): Promise<boolean> {
		try {
			await this.db.ready();
			await this.db.clear();
			MDFEvent.emit(MDFDBCLEAR, { db: this._name });
			return true;
		} catch (error) {
			console.log('clearDB', error);
			return true;
		}
	}

	public async deleteDB(): Promise<boolean> {
		try {
			await this.db.ready();
			await this.db.clear();
			await this.db.dropInstance();
			MDFEvent.emit(MDFDBCLEAR, { db: this._name });
			return true;
		} catch (error) {
			console.log('deleteDB', error);
			return true;
		}
	}

	// Hashing Function for Key name hashing
	private _hash(value: any) {
		// return sha256(value, this._privateKey).toString();
		const keyValue = this._privateKey;
		return value;
	}
}

export const OfflineDB = OfflineDatabase;
