import * as React from 'react';
import { WithStyles } from '@material-ui/core/styles';
import get from 'lodash/get';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import Style from './style';
import { APPLICATIONEVENTS } from '../../constants/events';
import { generateStyle } from '../../utils/functions';

export interface ContainerProps extends WithSnackbarProps, WithStyles<typeof Style> {
	// pass
}
/**
 *
 */
class NotificationComponent extends React.PureComponent<ContainerProps> {
	componentDidMount() {
		window.addEventListener(APPLICATIONEVENTS.NOTIFICATION, (event) => {
			const notification = get(event, 'detail', {});
			const message = get(notification, 'message', '');
			const config = get(notification, 'config', {});
			const { enqueueSnackbar } = this.props;
			enqueueSnackbar(message, { ...config, action: this.action });
		});
	}

	action = (key) => {
		const { closeSnackbar } = this.props;
		return (
			<>
				<IconButton
					size="small"
					onClick={() => {
						closeSnackbar(key);
					}}
					color="inherit"
				>
					<Close color="inherit" />
				</IconButton>
			</>
		);
	};

	/**
	 * Render method
	 * @returns {JSX.Element} Jsx.
	 */
	public render() {
		return <></>;
	}
}

export default generateStyle(Style, 'NotificationComponent')(withSnackbar(NotificationComponent));
