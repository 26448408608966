import * as React from 'react';
import {
	WithStyles,
	Button,
	Box,
	Typography,
	InputAdornment,
	Snackbar,
	IconButton,
	Collapse
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import clsx from 'clsx';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import get from 'lodash.get';
import truncate from 'lodash/trim';
import { Visibility, AccountBox, VisibilityOff, ArrowForward, ArrowBack } from '@material-ui/icons';
import Style from './style';
import { CommonPropsWithRef } from '../../utils/generics';
import { withRef } from '../../hoc/withRef';
import { isPhoneNumber, isEmail, NotifyError, generateStyle, isEmpID } from '../../utils/functions';
import LoadingButton from '../LoadingButton';
import { MDFAjax } from '../../network';
import { LOGINCONFIG } from '../../constants/variables';
import { ActionStatus } from '../../constants/api';
import { AuthEvents } from '../../events';
import { MDFLOGOUT } from '../../constants/events';
import Resources from '../../constants/resource';
import { Notify } from '../../utils/events';

ValidatorForm.addValidationRule('isEmailOrPhoneNumber', (value) => {
	if (!value) {
		return true;
	}
	if (isEmpID(value)) {
		return true;
	}
	if (isPhoneNumber(value)) {
		return true;
	}
	if (isEmail(value)) {
		return true;
	}
	return false;
});
const SETPASSWORDMODE = ['update_password', 'set_password'];

interface Props extends CommonPropsWithRef<HTMLDivElement>, WithStyles<typeof Style> {
	// pass
	onSuccess?: (e) => void;
	api: MDFAjax<keyof typeof LOGINCONFIG>;
	username?: string;
	userReference?: Record<string, any>;
}
interface State {
	status: ActionStatus;
	mode: 'set_password' | 'enter_password' | 'next' | 'update_password';
	apiresponse: any;
	message: React.ReactNode;
	passwordVisible: boolean;
	confirmPasswordVisible: boolean;
	user: {
		username: string;
		password: string;
		confirmPassword: string;
	};
}
/**
 *
 */
class Container extends React.Component<Props, State> {
	private _userNameRef: any = React.createRef<any>();

	private _passWordRef: any = React.createRef<any>();

	constructor(props: Props) {
		super(props);
		const { username = '', userReference = {} } = this.props;
		const usernameRef = get(userReference, 'username', '');
		const mode = get(userReference, 'mode', 'enter_password');
		const apiresponse = get(userReference, 'apiresponse', {});
		this.state = {
			status: ActionStatus.NONE,
			mode: username !== '' || usernameRef !== '' ? mode : 'next',
			message: '',
			apiresponse,
			passwordVisible: false,
			confirmPasswordVisible: false,
			user: {
				username: username! || usernameRef,
				password: '',
				confirmPassword: ''
			}
		};
		ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
			const { user } = this.state;
			const password = get(user, 'password', '');
			if (value !== password) {
				return false;
			}
			return true;
		});
	}

	componentDidMount = () => {
		const { username = '' } = this.props;
		setTimeout(() => {
			if (this._userNameRef && username === '') {
				this._userNameRef.focus();
			}
			if (this._passWordRef && username !== '') {
				this._passWordRef.focus();
			}
		}, 1000);
	};

	handleChange = (event) => {
		const { value } = event.target;
		const { name } = event.target;
		const { user } = this.state;
		user[name] = truncate(value);
		this.setState({ user, status: ActionStatus.NONE });
	};

	private _closeError = () => {
		this.setState({ status: ActionStatus.NONE });
	};

	handleSubmit = (e) => {
		// your submit logic
		const { user, mode, apiresponse } = this.state;
		const { onSuccess, api } = this.props;
		this.setState({ status: ActionStatus.LOADING }, () => {
			if (mode === 'next') {
				api.post(
					'precheck',
					{},
					{
						headers: {
							username: user.username
						}
					}
				)
					.toPromise()
					.then((d) => {
						const status = get(d, 'status', 200);
						if (status === 200) {
							this.setState({ status: ActionStatus.NONE, mode: 'enter_password' });
						}
						if (status === 202) {
							this.setState({ status: ActionStatus.NONE, mode: 'set_password' });
						}
						this._passWordRef.focus();
					})
					.catch((error) => {
						NotifyError(error);
						this.setState({ status: ActionStatus.NONE });
					});
			} else if (mode === 'set_password') {
				api.post(
					'anonymus',
					{},
					{
						headers: {
							username: user.username,
							password: user.password
						}
					}
				)
					.toPromise()
					.then((d) => {
						user.password = '';
						user.confirmPassword = '';
						this.setState({
							user,
							status: ActionStatus.NONE,
							mode: 'enter_password',
							passwordVisible: false,
							confirmPasswordVisible: false
						});
					})
					.catch((error) => {
						const type = get(error, 'response.data.type', '');
						if (type === 'ResetWindowExpiredException') {
							Notify(Resources.ResetWindowExpiredException, { variant: 'error' });
						} else {
							NotifyError(error);
						}
						this.setState({ status: ActionStatus.NONE });
					});
			} else if (mode === 'update_password') {
				const accessToken = get(apiresponse, 'accessToken', '');
				api.post(
					'update',
					{},
					{
						headers: {
							Authorization: accessToken,
							password: user.password
						}
					}
				)
					.toPromise()
					.then((d) => {
						if (onSuccess) {
							onSuccess({ ...apiresponse });
						}
					})
					.catch((error) => {
						const type = get(error, 'response.data.type', '');
						if (type === 'ResetWindowExpiredException') {
							Notify(Resources.ResetWindowExpiredException, { variant: 'error' });
						} else {
							NotifyError(error);
						}
						this.setState({ status: ActionStatus.NONE });
					});
			} else {
				this._authenticate();
			}
		});
	};

	private _authenticate = () => {
		const { user } = this.state;
		const { onSuccess, api } = this.props;
		api.post(
			'authenticate',
			{ authType: 'PASSWORDBASED' },
			{
				headers: {
					username: user.username,
					password: user.password
				}
			}
		)
			.toPromise()
			.then((d) => {
				if (onSuccess) {
					const oneTimePassword = get(d.data, 'oneTimePassword', false);
					const apiresponse = { ...d.data, userReference: user };
					if (oneTimePassword) {
						this.setState({
							status: ActionStatus.NONE,
							mode: 'update_password',
							user: {
								...user,
								password: ''
							},
							apiresponse
						});
					} else {
						onSuccess({ ...apiresponse });
					}
				}
			})
			.catch((d) => {
				NotifyError(d);
				this.setState({ status: ActionStatus.NONE });
			});
	};

	private _closeSnackbar = () => {
		this.setState({ status: ActionStatus.NONE });
	};

	private _resetForm = () => {
		const { user } = this.state;
		user.username = '';
		user.password = '';
		user.confirmPassword = '';
		this.setState(
			{
				status: ActionStatus.NONE,
				mode: 'next',
				user,
				passwordVisible: false,
				confirmPasswordVisible: false
			},
			() => {
				if (this._userNameRef) {
					this._userNameRef.focus();
				}
			}
		);
	};

	/**
	 * Render method
	 * @returns {JSX.Element} Jsx.
	 */
	public render() {
		const { classes, className, innerRef, username = '' } = this.props;
		const {
			user,
			status,
			message,
			passwordVisible,
			confirmPasswordVisible,
			mode,
			apiresponse
		} = this.state;
		const userId = get(user, 'username', username);
		let submitButton = 'next';
		if (mode === 'enter_password') {
			submitButton = 'login';
		}
		if (SETPASSWORDMODE.includes(mode)) {
			submitButton = 'set password';
		}
		return (
			<div className={clsx(classes.root, className)} ref={innerRef}>
				{username === '' && (
					<Typography variant="h6" className={classes.title} align="center">
						{SETPASSWORDMODE.includes(mode) ? 'Set Password' : 'Login'}
					</Typography>
				)}
				{status === ActionStatus.FAILURE && (
					<Snackbar
						open={status === ActionStatus.FAILURE}
						onClose={this._closeSnackbar}
						autoHideDuration={5000}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right'
						}}
					>
						<Alert
							variant="standard"
							elevation={3}
							severity="error"
							onClose={this._closeSnackbar}
						>
							{message}
						</Alert>
					</Snackbar>
				)}
				<ValidatorForm className={classes.form} onSubmit={this.handleSubmit}>
					<TextValidator
						label="Username *"
						placeholder={Resources.LoginInputTitle}
						onChange={this.handleChange}
						name="username"
						fullWidth
						autoComplete="true"
						disabled={mode !== 'next'}
						variant="outlined"
						margin="normal"
						helperText={
							<Typography
								color={mode === 'next' ? 'primary' : 'textSecondary'}
								variant="caption"
							>
								{Resources.LoginInputTitle}
							</Typography>
						}
						InputLabelProps={{
							shrink: true
						}}
						inputRef={(ref) => {
							this._userNameRef = ref;
						}}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<AccountBox color="primary" />
								</InputAdornment>
							)
						}}
						value={userId}
						validators={['required', 'isEmailOrPhoneNumber']}
						errorMessages={[Resources.InvalidLoginInput, Resources.InvalidLoginInput]}
					/>
					<Collapse in={mode !== 'next'}>
						{mode !== 'next' && (
							<TextValidator
								label="Password *"
								onChange={this.handleChange}
								name="password"
								fullWidth
								variant="outlined"
								inputRef={(ref) => {
									this._passWordRef = ref;
								}}
								helperText={
									<Typography color="primary" variant="caption">
										{Resources.EnterPasswordTitle}
									</Typography>
								}
								margin="normal"
								type={passwordVisible ? 'text' : 'password'}
								placeholder={Resources.EnterPasswordTitle}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												size="small"
												onClick={() =>
													this.setState({
														passwordVisible: !passwordVisible
													})
												}
											>
												{passwordVisible ? (
													<VisibilityOff color="primary" />
												) : (
													<Visibility color="primary" />
												)}
											</IconButton>
										</InputAdornment>
									)
								}}
								InputLabelProps={{
									shrink: true
								}}
								value={user.password}
								validators={['required']}
								errorMessages={[Resources.ValidPasswordValidation]}
							/>
						)}
					</Collapse>
					<Collapse in={SETPASSWORDMODE.includes(mode)}>
						{SETPASSWORDMODE.includes(mode) && (
							<TextValidator
								label="Confirm password *"
								placeholder={Resources.EnterNewPassword}
								onChange={this.handleChange}
								name="confirmPassword"
								fullWidth
								type={confirmPasswordVisible ? 'text' : 'password'}
								variant="outlined"
								helperText={
									<Typography color="primary" variant="caption">
										{Resources.NewPasswordTitle}
									</Typography>
								}
								margin="normal"
								InputLabelProps={{
									shrink: true
								}}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												size="small"
												onClick={() =>
													this.setState({
														confirmPasswordVisible: !confirmPasswordVisible
													})
												}
											>
												{confirmPasswordVisible ? (
													<VisibilityOff color="primary" />
												) : (
													<Visibility color="primary" />
												)}
											</IconButton>
										</InputAdornment>
									)
								}}
								value={user.confirmPassword}
								validators={['required', 'isPasswordMatch']}
								errorMessages={[
									Resources.InvalidConfirmPassword,
									Resources.PasswordNoMatch
								]}
							/>
						)}
					</Collapse>
					{username === '' && !SETPASSWORDMODE.includes(mode) && (
						<Typography
							component="a"
							href="/forgotPassword"
							className={classes.links}
							variant="subtitle2"
							color="inherit"
						>
							{Resources.LoginForgotPassword}
						</Typography>
					)}
					<Box className={classes.submit}>
						<LoadingButton loading={false}>
							{mode !== 'next' && username === '' ? (
								<Button
									disabled={status === ActionStatus.LOADING}
									variant="contained"
									onClick={this._resetForm}
									startIcon={<ArrowBack />}
									color="primary"
								>
									Back
								</Button>
							) : null}
						</LoadingButton>
						<div
							className={clsx({
								[classes.displayFlex]: mode !== 'next' && username !== ''
							})}
						>
							{mode !== 'next' && username !== '' ? (
								<LoadingButton>
									<Button
										onClick={(e) => {
											AuthEvents.emit(MDFLOGOUT);
										}}
										disabled={status === ActionStatus.LOADING}
										variant="contained"
										color="primary"
										className={classes.logout}
									>
										Logout
									</Button>
								</LoadingButton>
							) : null}
							<LoadingButton loading={status === ActionStatus.LOADING}>
								<Button
									type="submit"
									disabled={status === ActionStatus.LOADING}
									variant="contained"
									endIcon={mode === 'next' ? <ArrowForward /> : null}
									color="primary"
								>
									{submitButton}
								</Button>
							</LoadingButton>
						</div>
					</Box>
				</ValidatorForm>
			</div>
		);
	}
}

export default generateStyle(Style, 'LoginForm')(withRef(Container));
