import * as React from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Typography, Box, Button, InputAdornment } from '@material-ui/core';
import { Mail, CheckCircle } from '@material-ui/icons';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import LoadingButton from '../LoadingButton';
import Style from './style';
import { AuthEvents } from '../../events';
import { MDFLOGOUT } from '../../constants/events';
import { LOGINCONFIG } from '../../constants/variables';
import { MDFAjax } from '../../network';
import { ActionStatus } from '../../constants/api';
import {
	getRelativePath,
	NotifyError,
	isEmail,
	isEmpID,
	isPhoneNumber
} from '../../utils/functions';
import Resources from '../../constants/resource';

ValidatorForm.addValidationRule('isEmailOrPhoneNumber', (value) => {
	if (!value) {
		return true;
	}
	if (isEmpID(value)) {
		return true;
	}
	if (isPhoneNumber(value)) {
		return true;
	}
	if (isEmail(value)) {
		return true;
	}
	return false;
});

export interface Props extends WithStyles<typeof Style> {
	// pass
	api: MDFAjax<keyof typeof LOGINCONFIG>;
}
interface State {
	status: ActionStatus;
	message: React.ReactNode;
	formData: {
		email: string;
	};
}
/**
 *
 */
class ForgotPasswordForm extends React.Component<Props, State> {
	private _emailRef: any = React.createRef<any>();

	constructor(props: Props) {
		super(props);
		this.state = {
			status: ActionStatus.NONE,
			message: '',
			formData: {
				email: ''
			}
		};
	}

	componentDidMount() {
		AuthEvents.emit(MDFLOGOUT);
		setTimeout(() => {
			if (this._emailRef) {
				this._emailRef.focus();
			}
		}, 1000);
	}

	handleChange = (event) => {
		const { value } = event.target;
		const { name } = event.target;
		const { formData } = this.state;
		formData[name] = value;
		this.setState({ formData, status: ActionStatus.NONE });
	};

	handleSubmit = (e) => {
		const { api } = this.props;
		const { formData } = this.state;
		this.setState({ status: ActionStatus.LOADING }, () => {
			api.post(
				'forget',
				{},
				{
					headers: {
						username: formData.email
					}
				}
			)
				.toPromise()
				.then((data) => {
					this.setState({ status: ActionStatus.SUCCESS, formData: { email: '' } });
					if (this._emailRef) {
						this._emailRef.focus();
					}
				})
				.catch((error) => {
					NotifyError(error, 'forgotPassword');
					this.setState({ status: ActionStatus.FAILURE });
				});
		});
	};

	private _closeError = () => {
		this.setState({ status: ActionStatus.NONE });
	};

	private _closeSnackbar = () => {
		this.setState({ status: ActionStatus.NONE });
	};

	public renderForm = () => {
		const { classes } = this.props;
		const { status, formData, message } = this.state;
		if (status === ActionStatus.SUCCESS) {
			return (
				<Box className={classes.successMessage}>
					<img
						src={getRelativePath('images/email-sent.webp')}
						alt="Email sent"
						className={classes.emailSent}
					/>
					<Box className={classes.message}>
						<CheckCircle color="primary" className={classes.messageIcon} />
						<Typography variant="body2">{Resources.EmailLinkSentMessage}</Typography>
					</Box>
				</Box>
			);
		}
		return (
			<>
				<Typography paragraph variant="body2">
					{Resources.ResetPasswordMessage}
				</Typography>

				<ValidatorForm onSubmit={this.handleSubmit}>
					<TextValidator
						label="Username *"
						placeholder={Resources.LoginInputTitle}
						onChange={this.handleChange}
						name="email"
						fullWidth
						variant="outlined"
						helperText={Resources.LoginInputTitle}
						inputRef={(ref) => {
							this._emailRef = ref;
						}}
						margin="normal"
						InputLabelProps={{
							shrink: true
						}}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<Mail color="primary" />
								</InputAdornment>
							)
						}}
						value={formData.email}
						validators={['required', 'isEmailOrPhoneNumber']}
						errorMessages={[Resources.InvalidLoginInput, Resources.InvalidLoginInput]}
					/>
					<Box mt={1}>
						<LoadingButton loading={status === ActionStatus.LOADING}>
							<Button
								type="submit"
								disabled={status === ActionStatus.LOADING}
								variant="contained"
								fullWidth
								color="primary"
							>
								{Resources.RecoverEmail}
							</Button>
						</LoadingButton>
					</Box>
				</ValidatorForm>
			</>
		);
	};

	/**
	 * Render method
	 * @returns {JSX.Element} Jsx.
	 */
	public render() {
		const { classes } = this.props;
		const { status } = this.state;
		return (
			<div className={clsx(classes.root)}>
				{status !== ActionStatus.SUCCESS && (
					<Typography className={classes.title} variant="h6">
						Login
					</Typography>
				)}
				{status === ActionStatus.SUCCESS && (
					<Typography className={classes.title} variant="h6">
						{Resources.RecoveryLinkSent}
					</Typography>
				)}
				{status !== ActionStatus.SUCCESS && (
					<Typography className={classes.titleNormal} variant="h6">
						{Resources.ForgotPassword}
					</Typography>
				)}

				{this.renderForm()}
			</div>
		);
	}
}

export default withStyles(Style, { name: 'ForgotPasswordForm' })(ForgotPasswordForm);
