import { combineReducers } from 'redux';
import MainReducer from '../redux/Root/reducers';
import HomeReducer from '../redux/Home/reducers';
import FileReducer from '../redux/File/reducers';

const Reducer = combineReducers({
	main: MainReducer,
	file: FileReducer,
	applications: HomeReducer
});

export type RootState = ReturnType<typeof Reducer>;
export default Reducer;
