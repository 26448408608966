enum Resources {
	SessionExpired = 'Session Expired',
	SessionExpiredAlert = 'Session expired. Please login again',
	LoginSessionExpired = 'Login Session expired',
	SessionExpiredPopupAlert = 'Your session seems to have expired please login again to keep working without any interruption.',
	NavigateAway = 'Are you sure you want to navigate away from this page? You will lose all the changes made since you were on this page.',
	AllAppsTitle = 'All Apps',
	ResolutionAlert = 'Site best viewed with a minimum resolution of 1280x800',
	Continue = 'Do you wish to continue?',
	EmailLinkSentMessage = 'An email with the link has been sent. Please click the link to reset your password.',
	ResetPasswordMessage = 'Enter your e-mail address and we’ll send you a link to reset your password.',
	InvalidEmail = 'Please enter a valid Email address.',
	RecoverEmail = 'Email me a recovery link',
	RecoveryLinkSent = 'Recovery Link Sent',
	ForgotPassword = 'Forgot Your Password ?',
	AllAppsText = 'Apps to ease your work',
	SpeakUpText = 'Give anonymous feedback or raise a complaint',
	SettingsText = 'Change the appearance and profile',
	LoginInputTitle = 'Email-Id / Phone number/ Employee ID',
	InvalidLoginInput = 'Please enter a valid Email-Id/Phone number/Employee ID.',
	EnterPasswordTitle = 'Enter Password',
	InvalidConfirmPassword = 'Please enter a valid confirm password.',
	EnterNewPassword = 'Enter the new password again',
	NewPasswordTitle = 'Repeat Password',
	RepeatNewPassword = 'Repeat the new password',
	PasswordNoMatch = 'Password do not match.',
	LoginForgotPassword = 'Forgot Password?',
	OTPSentMessage = 'We’ve sent an OTP to your registered mobile number and email.',
	PasswordResetSuccessful = 'Your password has been reset successfully.',
	LoginLinkTitle = 'Click here to login.',
	PasswordPlaceholder = 'Enter new password',
	ValidPasswordValidation = 'Please enter a valid password.',
	PasswordLengthValidation = 'Password should be minimum 6 character long.',
	ResetPassword = 'Reset Password',
	InstallCordova = 'Please install needed libraries',
	Login = 'Login',
	ResetWindowExpiredException = 'Time window for reset password is expired. Please reset password again.'
}

export default Resources;
